import {
  Column,
  Container,
  Icon,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IAlviCouponWithActivation } from '@smu-chile/pkg-unimarc-hooks/hooks/useCouponsAlvi'
import { CouponDetailContent } from 'components/CouponDetail/CouponDetailContent/CouponDetailContent'
import { CouponDetailModal } from 'components/CouponDetail/CouponDetailModal/CouponDetailModal'

export type CouponModalAlviProps = {
  coupon?: IAlviCouponWithActivation
  onClose?: VoidFunction
  onCouponActivateClick?: VoidFunction
}

export const CouponModal = ({
  coupon,
  onClose,
  onCouponActivateClick
}: CouponModalAlviProps) => {
  return (
    <>
      <BigScreen>
        <CouponDetailModal
          onToggle={onClose}
          open={true}
        >
          <Container
            isWrap
            minWidth='800px'
          >
            <CouponDetailContent
              activeLoading={coupon?.isActivating}
              coupon={coupon}
              isLoggedIn={false}
              onActive={onCouponActivateClick}
              onShelfCatch={() => {
                return
              }}
            />
          </Container>
        </CouponDetailModal>
      </BigScreen>

      <SmallScreen>
        <MobileModalWrapper
          blockId='couponDetailModalMobile'
          body={
            <Column>
              <CouponDetailContent
                activeLoading={coupon?.isActivating}
                coupon={coupon}
                isLoggedIn={false}
                onActive={onCouponActivateClick}
                onShelfCatch={() => {
                  return
                }}
              />
            </Column>
          }
          bodyContainerProps={{
            backgroundColor: '#f4f4f4',
            customClassName: '',
            customHeight: '100%',
            padding: '0 16px'
          }}
          dragProps={{
            draggButton: false
          }}
          hasFooter={false}
          headerDividerProps={{
            verticalMargin: 0
          }}
          headerLeftElement={
            <Icon
              color={getGlobalStyle('--color-neutral-black')}
              customSize={16}
              name='ArrowBackNavigate'
              onClick={onClose}
            />
          }
          headerPadding='0'
          headerProps={{
            alignItems: 'center',
            padding: '0 20px 20px'
          }}
          headerTitle='Detalle de cupón'
          headerTitleProps={{
            containerProps: {
              alignItems: 'start'
            },
            textProps: {
              textAlign: 'left'
            }
          }}
          iconSize={0}
          isEnableButton={false}
          isHiddenHeaderCloseButton
          modalConfigsProps={{
            fullScreenSticky: true,
            isAutoHeight: false,
            isOpen: true,
            marginFullScreen: '',
            toggle: onClose,
            toggleOutside: onClose
          }}
          styleProps={{
            borderRadius: '0'
          }}
        />
      </SmallScreen>
    </>
  )
}
