import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'

export interface PromotionReturn {
  isPromotion: boolean
  price: number
  ppum: string
  offer: string
}

export const statusPromotions = (product: Product): PromotionReturn => {
  let isPromotion = false
  let price: string | number = product?.sellers?.[0]?.price
  let ppum: string = product?.sellers?.[0]?.ppum
  let offer: string

  if (product?.promotion?.['hasSavings']) {
    price = product?.promotion?.['price']
    ppum = product?.promotion?.['ppum']

    //Nxp
    if (product?.promotion?.['descriptionCode'] === 4) {
      isPromotion = true
      const messagePromo = product?.promotion['descriptionMessage']
      const untilIndex = messagePromo.indexOf('x')
      const amountProductIndex = messagePromo.indexOf('$')
      price = parseInt(
        messagePromo
          .slice(amountProductIndex + 1, messagePromo.length + 1)
          ?.replace('.', '')
      )

      offer = messagePromo.slice(0, untilIndex + 1)
    }

    //Mxn
    if (product?.promotion?.['descriptionCode'] === 3) {
      isPromotion = true
      const clearOffer = product?.promotion?.['saving']
      offer = clearOffer.toString()?.replace(/\s/g, '')
    }

    //porcentual
    if (product?.promotion?.['descriptionCode'] === 2) {
      isPromotion = true
      price = product?.promotion?.['price']
      const porcent = getPorcent(
        product?.sellers[0]?.listPrice,
        parseInt(price.toString())
      )
      offer = `${porcent}%`
    }
  }

  return {
    isPromotion,
    offer,
    ppum,
    price: parseInt(price.toString())
  }
}

const getPorcent = (
  listPrice: string | number,
  priceDiscount: number
): number => {
  return Math.round(
    100 - (priceDiscount * 100) / parseInt(listPrice.toString())
  )
}

export const promotionsOfShelves = ({ data }) => {
  return data?.items[0]?.fields?.campaings?.map(({ sys }) => {
    // Get all references of other contents id
    const fields = data?.includes?.Entry?.find((entry) => {
      return entry.sys.id == sys.id
    })?.fields

    // Get all references of the media files
    if (fields?.campaignIcon) {
      const Assets = data?.includes?.Asset?.find((asset) => {
        return fields?.campaignIcon?.sys?.id == asset.sys.id
      })?.fields

      if (Assets) fields.campaignIcon = Assets
    }
    return { id: sys?.id, ...fields }
  })
}

export const getDataPromotion = (dataPromotions, name) => {
  let promotion = null

  if (dataPromotions) {
    const promotionId = /C(\d+)/.exec(name)
    if (promotionId) {
      const promotionTemp = dataPromotions?.find((dataPromotion) => {
        return promotionId[0] == dataPromotion?.['campaignId']
      })

      if (promotionTemp) {
        promotion = {
          backgroundColor: promotionTemp?.['color'],
          color: promotionTemp?.['textColor'],
          label: promotionTemp?.['campaignName'],
          image: promotionTemp?.['campaignIcon']?.file?.url
        }
      }
    }
  }

  return promotion
}
