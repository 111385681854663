// ref: https://nextjs.org/docs/advanced-features/error-handling
// ret: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/

import { ErrorViewProps } from '@smu-chile/pkg-unimarc-components'
import React, { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorPage } from '../ErrorPage'
import { ErrorPageV2 } from 'components/ErrorPageV2'

interface Props {
  children?: ReactNode
  inComponent?: string
}

interface State {
  hasError: boolean
  customViewError?: CustomViewError
}

interface CustomViewErrorProps extends Omit<ErrorViewProps, 'onClick'> {
  layout?: 'club'
}

export class CustomViewError {
  constructor(public props: CustomViewErrorProps) {}
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    customViewError: undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error | CustomViewError): State {
    if (_ instanceof CustomViewError) {
      return { hasError: true, customViewError: _ }
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    // console.log(typeof this.props.children.type, 'children')
    if (this.state.hasError) {
      if (
        this.props.inComponent === 'club-alvi' ||
        this.state.customViewError?.props?.layout === 'club'
      ) {
        return <ErrorPageV2 />
      }

      return (
        <ErrorPage
          {...this.state.customViewError?.props}
          redirect={true}
          site='alvi'
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
