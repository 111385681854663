import { getContentfulData } from '@smu-chile/pkg-unimarc-hooks'
import {
  CONTENTFUL_EMOJI_CELEBRATION,
  CONTENTFUL_EMOJI_LOCATION,
  CONTENTFUL_EMOJI_STORE_DELIVERY,
  CONTENTFUL_EMOJI_STORE_PICKUP
} from 'shared/utils/constanst'

export async function getContentfulEmojiCelebration() {
  const contentful = await getContentfulData({
    options: {
      'sys.id': CONTENTFUL_EMOJI_CELEBRATION
    },
    reactQuery: {
      queryKey: 'storeLocatorEmojiCelebration'
    },
    type: 'assets'
  })

  const [emojiCelebration] = contentful?.items ?? []
  return emojiCelebration ?? null
}

export async function getContentfulEmojiLocation() {
  const contentful = await getContentfulData({
    options: {
      'sys.id': CONTENTFUL_EMOJI_LOCATION
    },
    reactQuery: {
      queryKey: 'storeLocatorEmojiLocation'
    },
    type: 'assets'
  })

  const [emojiLocation] = contentful?.items ?? []
  return emojiLocation ?? null
}

export async function getContentfulEmojiStoreDelivery() {
  const contentful = await getContentfulData({
    options: {
      'sys.id': CONTENTFUL_EMOJI_STORE_DELIVERY
    },
    reactQuery: {
      queryKey: 'storeLocatorEmojiStoreDelivery'
    },
    type: 'assets'
  })

  const [emojiStoreDelivery] = contentful?.items ?? []
  return emojiStoreDelivery ?? null
}

export async function getContentfulEmojiStorePickup() {
  const contentful = await getContentfulData({
    options: {
      'sys.id': CONTENTFUL_EMOJI_STORE_PICKUP
    },
    reactQuery: {
      queryKey: 'storeLocatorEmojiStorePickup'
    },
    type: 'assets'
  })

  const [emojiStorePickup] = contentful?.items ?? []
  return emojiStorePickup ?? null
}
