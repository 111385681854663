import {
  Column,
  Container,
  Icon,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { WrapTooltip, WrapTooltipProps } from './WrapTooltip'
import { getRetryAfterText } from '../helpers/getRetryAfterText'

export type CodeBlockedTooltipProps = WrapTooltipProps & {
  retryAfter?: number
}

export const CodeBlockedTooltip = ({
  retryAfter,
  ...props
}: CodeBlockedTooltipProps) => {
  return (
    <WrapTooltip
      variant='error'
      {...props}
    >
      <Container>
        <Row
          alignItems='center'
          gap={8}
        >
          <Column maxWidth='max-content'>
            <Icon
              color={getGlobalStyle('--color-feedback-error-light')}
              customSize={20}
              name='InfoFill'
            />
          </Column>

          <Column margin='0 10px 0 0'>
            <Text
              color='black'
              display='block'
              fontSize='md'
              fontWeight='medium'
            >
              Has superado el número de intentos. Vuelve a intentarlo en{' '}
              {getRetryAfterText(retryAfter)}.
            </Text>
          </Column>
        </Row>
      </Container>
    </WrapTooltip>
  )
}
