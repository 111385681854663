import { NextRouter } from 'next/router'

/**
 * Validate if next-router has a valid router to show StoreLocator
 * @param {NextRouter} router - input router
 * @returns {boolean}
 */
export function validateStoreLocatorAvailableRoute(
  router?: NextRouter
): boolean {
  return (
    router?.query?.login !== 'true' &&
    router?.query?.welcome !== 'merchant' &&
    router?.query?.['forgot-password'] !== 'true'
  )
}
