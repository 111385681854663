import { ReactChild } from 'react'
import { Column, Container, Text } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { CouponErrorIcon } from '../CouponErrorIcon/CouponErrorIcon'

export interface CouponDetailMessageProps {
  action?: ReactChild
  subtitle?: ReactChild
  title?: ReactChild
  errorType?: 'loadingProducts' | 'noProducts'
}

export const CouponDetailMessage = ({
  action,
  subtitle,
  title,
  errorType
}: CouponDetailMessageProps) => {
  return (
    <>
      <Container
        justifyContent='center'
        padding='16px'
      >
        <CouponErrorIcon errorType={errorType} />
      </Container>

      <BigScreen>
        <Column
          alignItems='center'
          gap='8px'
        >
          <Text
            fontSize='5xl'
            fontWeight='bold'
            textAlign='center'
          >
            {title}
          </Text>

          <Text
            fontSize='xl'
            textAlign='center'
          >
            {subtitle}
          </Text>
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column
          alignItems='center'
          gap='8px'
        >
          <Text
            fontWeight='bold'
            textAlign='center'
          >
            {title}
          </Text>

          <Text
            fontSize='sm'
            isFlex={false}
            textAlign='center'
          >
            {subtitle}
          </Text>
        </Column>
      </SmallScreen>

      {action && (
        <Container
          justifyContent='center'
          padding='16px'
        >
          {action}
        </Container>
      )}
    </>
  )
}
