import {
  Column,
  Container,
  Icon,
  Link,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { getRegisterUrl } from '@smu-chile/pkg-unimarc-hooks'
import { WrapTooltip, WrapTooltipProps } from './WrapTooltip'

export type UserNotFoundTooltipProps = WrapTooltipProps

export const UserNotFoundTooltip = (props: UserNotFoundTooltipProps) => {
  return (
    <WrapTooltip
      variant='error'
      {...props}
    >
      <Container>
        <Row
          alignItems='center'
          gap={8}
        >
          <Column maxWidth='max-content'>
            <Icon
              color={getGlobalStyle('--color-feedback-error-light')}
              customSize={20}
              name='InfoFill'
            />
          </Column>

          <Column margin='0 10px 0 0'>
            <Text
              color='black'
              display='block'
              fontSize='md'
              fontWeight='medium'
            >
              Usuario no registrado, te invitamos a hacerlo{' '}
              <Link
                color='black'
                fontSize='md'
                fontWeight='semibold'
                href={getRegisterUrl()}
                underline='always'
              >
                aquí
              </Link>
              .
            </Text>
          </Column>
        </Row>
      </Container>
    </WrapTooltip>
  )
}
