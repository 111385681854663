import { useRouter } from 'next/router'
import { Column, Container } from '@smu-chile/pkg-unimarc-components'
import { TitleBox } from '@smu-chile/pkg-unimarc-components/stories/molecules/TitleBox/TitleBox'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { getBorderStyle } from '@smu-chile/pkg-unimarc-components/helpers/getBorderStyle'
import { CouponCarousel } from 'components/CouponCarousel'
import { useCouponsAvailable } from './useCouponsAvailable'

export const bannersWebItemTokens = {
  border: getBorderStyle('--border-width-xs', 'solid', '--color-neutral-black'),
  borderRadius: getGlobalStyle('--border-radius-full')
}

export type BannersWebItemProps = {
  content?: unknown
}

export const BannersWebItem = ({ content }: BannersWebItemProps) => {
  const router = useRouter()

  const contentAvailableLevels =
    (content?.['alviEnabledLevels'] as Array<string>) ?? []
  const couponsAvailable = useCouponsAvailable({
    levels: contentAvailableLevels
  })

  const handleTitleButtonClick = () => {
    if (content?.['actionButton']) {
      router.push(`${content?.['actionButton']}`)
    }
  }

  if (!couponsAvailable.enabled) {
    return null
  }

  return (
    <Column alignItems='center'>
      <BigScreen>
        <Column
          gap={32}
          maxWidth='1122px'
        >
          <TitleBox
            buttonBorder={bannersWebItemTokens.border}
            buttonBorderRadius={bannersWebItemTokens.borderRadius}
            buttonText={content?.['textButton']}
            buttonType='secondary'
            onButtonClick={handleTitleButtonClick}
            subtitle={content?.['subtitle']}
            subtitleSize='lg'
            subtitleWeight='medium'
            title={content?.['title']}
            titleSize='2xl'
            titleWeight='medium'
          />

          {content?.['itemFormat'] === 'cupones' && <CouponCarousel />}
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column gap={8}>
          <Container padding='16px'>
            <TitleBox
              buttonBorder={bannersWebItemTokens.border}
              buttonBorderRadius={bannersWebItemTokens.borderRadius}
              buttonHeight='32px'
              buttonText={content?.['textButton']}
              buttonTextSize='12px'
              buttonType='secondary'
              onButtonClick={handleTitleButtonClick}
              subtitle={content?.['subtitle']}
              subtitleSize='sm'
              subtitleWeight='medium'
              title={content?.['title']}
              titleSize='lg'
              titleWeight='medium'
            />
          </Container>

          {content?.['itemFormat'] === 'cupones' && <CouponCarousel />}
        </Column>
      </SmallScreen>
    </Column>
  )
}
