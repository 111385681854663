import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'

import {
  BodyPageProducts,
  BodyPageProductsProps
} from 'components/BodyPageProducts'

export const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-alvi-neutral-gray-white')}
      justifyContent='center'
      tagName='main'
    >
      {children}
    </Container>
  )
}
export const SearchBodyProduct = (
  dataOfBodyPageProducts: JSX.IntrinsicAttributes & BodyPageProductsProps
) => {
  return (
    <Main>
      <BigScreen>
        <Column alignItems='center'>
          <Spacer.Horizontal size={24} />
          <Container
            backgroundColor={getGlobalStyle('--color-alvi-neutral-gray-white')}
            customWidth='90'
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='12px 36px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
          </Container>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column>
          <Spacer.Horizontal size={24} />
          <Row>
            <Column
              backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
            >
              <BodyPageProducts {...dataOfBodyPageProducts} />
              <Spacer.Horizontal size={160} />
            </Column>
          </Row>
        </Column>
      </SmallScreen>
    </Main>
  )
}
