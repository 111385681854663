import { useRouter } from 'next/router'
import {
  Text,
  Column,
  Container,
  Row,
  Spacer,
  Picture,
  TextProps
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { MEMBERSHIP_LEVELS } from 'shared/utils/constanst'
import { formatTextWithLineBreaks } from '@smu-chile/pkg-unimarc-components/shared/helpers'
import { TFontSize } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontSize'

interface IModalMembershipTypeContentConfig {
  imageWidth: string
  imageHeight: string
  maxWidthRow: string
  modalMessage: string
  titleSize: TFontSize
  titleWeight: TextProps['fontWeight']
  modalMessageSize: TFontSize
  modalMessageBoldyTextWeight: TextProps['fontWeight']
  modalMessageBoldyTextSize: TFontSize
}
interface IModalMembershipTypeContent {
  mobile: IModalMembershipTypeContentConfig
  desktop: IModalMembershipTypeContentConfig
}

const MODAL_MEMBERSHIP_TYPE_CONTENT: IModalMembershipTypeContent = {
  mobile: {
    imageWidth: '343px',
    imageHeight: '258px',
    maxWidthRow: '343px',
    modalMessage:
      'Disfruta los beneficios y descuentos\nque el Club Alvi tiene para ti.',
    modalMessageSize: 'lg',
    modalMessageBoldyTextWeight: 'bold',
    modalMessageBoldyTextSize: 'md',
    titleSize: '2xl',
    titleWeight: 'medium'
  },
  desktop: {
    imageWidth: '344px',
    imageHeight: '202px',
    maxWidthRow: '240px',
    modalMessage:
      'Disfruta los beneficios y descuentos\nque el Club Alvi tiene para ti.',
    modalMessageSize: 'base',
    modalMessageBoldyTextWeight: 'medium',
    modalMessageBoldyTextSize: 'lg',
    titleSize: '3xl',
    titleWeight: 'medium'
  }
}

export const ModalMembershipTypeContent = ({
  membershipLevel
}: {
  membershipLevel: string
}): React.ReactElement => {
  const router = useRouter()
  const blockId = 'modalMembershipTypeContent'
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const configValue = isMobile
    ? MODAL_MEMBERSHIP_TYPE_CONTENT.mobile
    : MODAL_MEMBERSHIP_TYPE_CONTENT.desktop
  const modalMessage = configValue.modalMessage

  return (
    <>
      <BigScreen>
        <Container
          alignItems='center'
          gap={20}
          grid
          gridTemplateColumns={2}
          maxWidth='100%'
          position='relative'
          tagName='section'
        >
          <Column alignItems='center'>
            {/* welcome message */}
            <Row
              justifyContent='center'
              tagName='article'
            >
              <Text
                fontSize={configValue.titleSize}
                fontWeight={configValue.titleWeight}
              >
                ¡Felicidades!
              </Text>
            </Row>
            <Spacer.Horizontal customSize={isMobile ? 12 : 14} />
            {/* message */}
            <Row
              alignItems='center'
              id={getBemId(blockId, 'altiro-logo')}
              justifyContent='center'
              tagName='article'
              width='100%'
            >
              <Column alignItems='center'>
                <Text
                  fontSize={configValue.modalMessageSize}
                  id={getBemId(blockId, 'welcome-text')}
                  textAlign='center'
                >
                  {formatTextWithLineBreaks(modalMessage)}
                </Text>
              </Column>
            </Row>
          </Column>
          <Column>
            {/* image membership type */}
            <Row
              maxWidth={configValue.maxWidthRow}
              tagName='article'
            >
              <Picture
                alt={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
                height={configValue.imageHeight}
                src={`${router.basePath}/${MEMBERSHIP_LEVELS[membershipLevel]?.url}`}
                title={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
                width={configValue.imageWidth}
              />
            </Row>
          </Column>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          maxWidth='100%'
          position='relative'
          tagName='section'
        >
          <Column alignItems='center'>
            {/* welcome message */}
            <Row
              justifyContent='center'
              tagName='article'
            >
              <Text
                fontSize={configValue.titleSize}
                fontWeight={configValue.titleWeight}
              >
                ¡Felicidades!
              </Text>
            </Row>
            <Spacer.Horizontal customSize={isMobile ? 12 : 14} />
            {/* message */}
            <Row
              alignItems='center'
              id={getBemId(blockId, 'altiro-logo')}
              justifyContent='center'
              tagName='article'
              width='100%'
            >
              <Column alignItems='center'>
                <Text
                  fontSize={configValue.modalMessageSize}
                  id={getBemId(blockId, 'welcome-text')}
                  textAlign='center'
                >
                  {formatTextWithLineBreaks(modalMessage)}
                </Text>
              </Column>
            </Row>
            {/* image membership type */}
            <Row
              maxWidth={configValue.maxWidthRow}
              tagName='article'
            >
              <Picture
                alt={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
                height={configValue.imageHeight}
                src={`${router.basePath}/${MEMBERSHIP_LEVELS[membershipLevel]?.url}`}
                title={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
                width={configValue.imageWidth}
              />
            </Row>
          </Column>
        </Container>
      </SmallScreen>
    </>
  )
}
