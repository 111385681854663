import { useRef } from 'react'
import { Container, Tooltip } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useOutside } from '@smu-chile/pkg-unimarc-components/shared/hooks'
import { useAfterOf } from '@smu-chile/pkg-unimarc-hooks'

export interface WrapTooltipProps {
  children?: React.ReactElement
  onClose?: VoidFunction
  variant?: 'error' | 'info'
}

export const WrapTooltip = ({
  children,
  onClose,
  variant
}: WrapTooltipProps) => {
  const tooltipRef = useRef()

  useAfterOf({
    ms: 5000,
    onFinish: onClose
  })

  useOutside({
    onClick: onClose,
    ref: tooltipRef
  })

  const customBackground = {
    error: getGlobalStyle('--color-feedback-error-bg'),
    info: getGlobalStyle('--color-feedback-info-bg')
  }

  return (
    <Container
      position='relative'
      ref={tooltipRef}
    >
      <Tooltip
        alignAllItems='center'
        background={customBackground[variant]}
        borderRadius={getGlobalStyle('--border-radius-2sm')}
        boxShadow={getGlobalStyle('--box-shadow-3md')}
        closeColor={getGlobalStyle('--color-icons-black')}
        closeIcon
        containerProps={{
          zIndex: '2'
        }}
        display='flex'
        margin='8px 0 0'
        maxWidth='343px'
        onClick={onClose}
        padding='12px'
        position='absolute'
        positionContainer='sticky'
        right='auto'
        textComponent={children}
        type='bottom'
      />
    </Container>
  )
}
