import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Backbone,
  Breadcrumbs,
  Column,
  Container,
  CouponProps,
  CouponStatus,
  Divider,
  Icon,
  Pagination,
  Row,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen,
  transformCouponLineThrough
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Cookies,
  patchCoupon,
  useAlviSession,
  useAlviCoupons,
  useCouponsSections,
  usePagination,
  useTrigerEvent,
  useMobile,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { IAlviCoupon } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAlviCoupon'
import { CouponSimpleList } from './CouponSimpleList'
import { FilterButton } from './FilterButtons'
import { CouponDetail } from '../CouponDetail/CouponDetail'

export interface CouponListPageProps {
  page?: number
  tab?: string
}

const COUPONS_PER_PAGE = 20 as const
export const COUPON_DEFAULT_IMAGE =
  'https://images.ctfassets.net/un6yvtd6uq5z/4QSScvaas23f1ucmgxGoDT/04203f555c88f7b67726e33897bda89f/placeholder.png?h=250'

export const statusMap: Record<string, CouponStatus> = {
  unactived: undefined,
  actived: 'active',
  used: 'used'
}

export const CouponListPage = ({ page, tab }: CouponListPageProps) => {
  const { isLoggedIn } = useAlviSession()

  const couponsResult = useAlviCoupons()
  const { isLoading } = couponsResult

  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const couponsSectionsResult = useCouponsSections()

  const [scrollToY, setScrollToY] = useState(0)

  const sections = useMemo(() => {
    return couponsSectionsResult.data?.data ?? []
  }, [couponsSectionsResult])

  const coupons = useMemo(() => {
    return couponsResult.data?.data ?? []
  }, [couponsResult])

  const router = useRouter()

  const [selectedFilter, setSelectedFilter] = useState<string>(tab)
  const [loadingCoupon, setLoadingCoupon] = useState<string[]>([])

  const [selectedCouponId, setSelectedCouponId] = useState<string>(null)

  const handleClickBreadcrumb = (url: string) => {
    router.push(url)
  }

  const { trigger } = useTrigerEvent()

  const handleActiveCoupon = useCallback(
    async (couponId: string) => {
      if (!isLoggedIn) {
        trigger({ eventType: 'loginModal', data: { show: true } })
        return
      }

      if (
        loadingCoupon.find((couponId) => {
          return couponId === couponId
        })
      )
        return

      setLoadingCoupon((prev) => {
        return [...prev, couponId]
      })

      await patchCoupon(couponId)
      await couponsResult.refetch()

      setLoadingCoupon((prev) => {
        return prev.filter((couponId) => {
          return couponId != couponId
        })
      })

      if (!selectedCouponId) {
        //set selectedCouponId only if it's not set yet
        setSelectedCouponId((prev) => {
          return !prev ? couponId : prev
        })
      }
    },
    [isLoggedIn, trigger, selectedCouponId]
  )

  const filterButtonsCount = useMemo(() => {
    const count: Record<string, number> = {}
    if (coupons && !coupons['error']) {
      for (const couponItem of coupons) {
        for (const section of couponItem.sections) {
          count[section] = (count[section] ?? 0) + 1
        }
      }
    }
    return count
  }, [coupons])

  const handleShowDetail = (couponData: IAlviCoupon) => {
    setSelectedCouponId(couponData.id)
    setScrollToY(window.scrollY)
  }

  const handleDetailActive = () => {
    handleActiveCoupon(selectedCouponId)
  }

  const handleDetailToggle = () => {
    setSelectedCouponId(null)
  }

  const filterButtons = useMemo(() => {
    const filteredSections =
      Array.isArray(sections) &&
      sections.filter((section) => {
        return filterButtonsCount[section] > 0
      })

    const mappedSections =
      Array.isArray(filteredSections) &&
      filteredSections.map((filter) => {
        return {
          label: filter,
          onClick: () => {
            handleChangeFilter(filter)
          },
          active: filter === selectedFilter
        }
      })

    if (!selectedFilter && mappedSections.length > 0)
      mappedSections[0].active = true

    return mappedSections
  }, [sections, coupons, selectedFilter])

  const filteredCoupons = useMemo(() => {
    if (!selectedFilter) {
      return (
        isValidArrayWithData(coupons) &&
        coupons.filter((couponItem) => {
          return couponItem.sections.includes(filterButtons[0]?.label)
        })
      )
    }

    return (
      !coupons['error'] &&
      coupons.filter((couponItem) => {
        return couponItem.sections.includes(selectedFilter) || !selectedFilter
      })
    )
  }, [coupons, selectedFilter, filterButtons, filterButtonsCount])

  const pagination = usePagination({
    itemPerPage: COUPONS_PER_PAGE,
    totalQuantity: filteredCoupons.length,
    defaultPage: page ?? 1
  })

  const handleChangeFilter = (filter: string) => {
    setSelectedFilter(filter)
    handleChangePage(1)
    router.push(
      {
        href: window.location.href,
        query: {
          ...router.query,
          tab: filter
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const mappedCoupons = useMemo(() => {
    return (
      filteredCoupons &&
      filteredCoupons.map((couponItem): CouponProps => {
        return {
          badgeText: couponItem.channel,
          description: couponItem.description,
          imageSrc: couponItem.couponImage || COUPON_DEFAULT_IMAGE,
          lead: couponItem.lead,
          loading: loadingCoupon.some((couponId) => {
            return couponId == couponItem.id
          }),
          onClick: () => {
            return handleActiveCoupon(couponItem.id)
          },
          onClickViewProducts: () => {
            handleShowDetail(couponItem)
          },
          status: statusMap[couponItem.status],
          subtitle: couponItem.subtitle,
          title: couponItem.title,
          viewProductsText:
            couponItem.mechanicId == '11' ? 'Ver detalles' : 'Ver productos',
          unipay: couponItem.unipay,
          usedButtonText: 'Cupón utilizado',
          ...transformCouponLineThrough(couponItem.lineThrough || [])
        }
      })
    )
  }, [filteredCoupons, handleActiveCoupon, loadingCoupon])

  const paginatedCoupons = useMemo(() => {
    const startIndex = (pagination.currentPage - 1) * COUPONS_PER_PAGE
    const endIndex = Math.min(
      startIndex + COUPONS_PER_PAGE,
      mappedCoupons.length
    )

    return mappedCoupons && mappedCoupons.slice(startIndex, endIndex)
  }, [mappedCoupons, pagination])

  const handleBack = () => {
    router.push('/')
  }

  const handleChangePage = (newPage: number) => {
    pagination.setPage(newPage)
    router.push(
      {
        href: window.location.href,
        query: {
          ...router.query,
          page: newPage.toString()
        }
      },
      undefined,
      { shallow: true }
    )
    window.scrollTo(0, 0)
  }

  const handlePaginationNextPage = () => {
    pagination.nextPage()
    window.scrollTo(0, 0)
  }

  const handlePaginationPrevPage = () => {
    pagination.prevPage()
    window.scrollTo(0, 0)
  }

  const selectedCoupon = useMemo(() => {
    return (
      !coupons['error'] &&
      coupons.find((couponItem) => {
        return couponItem.id === selectedCouponId
      })
    )
  }, [selectedCouponId, coupons])

  useEffect(() => {
    if (!selectedCoupon) {
      window.scrollTo(0, scrollToY)
    } else {
      if (isMobile) window.scrollTo(0, 0)
    }
  }, [selectedCoupon])

  useEffect(() => {
    const idTokenCookie = Cookies.get('co_token')
    const couponError = couponsResult.data?.data?.['error']
    if (couponError && !isLoggedIn && idTokenCookie) {
      Cookies.remove('co_token')
      router.reload()
    }
  }, [couponsResult])

  return (
    <Container justifyContent='center'>
      <SmallScreen>
        {!selectedCoupon && (
          <Column>
            <Row
              alignItems='center'
              clickable='pointer'
              margin='30px 0 0 0'
              onClick={handleBack}
              padding='16px 22px'
            >
              <Icon
                color={getGlobalStyle('--color-text-black')}
                name='ArrowBackNavigate'
              />
              <Container margin='0 0 0 16px'>
                <Text fontWeight='semibold'>Cupones Club Alvi</Text>
              </Container>
            </Row>
            <Container padding='12px 20px'>
              <FilterButton
                isLoading={isLoading}
                items={filterButtons}
              />
            </Container>
            <Column
              gap='12px'
              padding='16px'
            >
              <CouponSimpleList
                coupons={mappedCoupons}
                isLoading={isLoading}
                selectedFilter={selectedFilter}
              />
            </Column>
          </Column>
        )}
      </SmallScreen>

      <BigScreen>
        <Container
          margin='40px 140px'
          maxWidth={getGlobalStyle('--width-max-desktop')}
        >
          <Column padding='16px'>
            <Column padding='0 0'>
              <Container>
                <Breadcrumbs
                  colorAlvi
                  links={[
                    {
                      label: 'Inicio',
                      url: '/',
                      oneClick: handleClickBreadcrumb
                    },
                    {
                      label: 'Cupones',
                      url: '/coupon',
                      oneClick: handleClickBreadcrumb
                    }
                  ]}
                />
              </Container>
              <Container
                alignItems='center'
                margin='14px 0'
              >
                <Container
                  customWidth='max-content'
                  margin='0 8px 0 0'
                >
                  <Title
                    customFontSize='18px'
                    fontWeight='semibold'
                    headingLevel='h1'
                  >
                    Cupones Club Alvi
                  </Title>
                </Container>
                {!isLoading && (
                  <Text fontWeight='semibold'>
                    ({coupons.length} resultados)
                  </Text>
                )}
                {isLoading && (
                  <Container
                    margin='6px 0 2px'
                    maxWidth='125px'
                  >
                    <Backbone
                      borderRadius='8px'
                      height={20}
                      width={125}
                    />
                  </Container>
                )}
              </Container>
              <Divider />
              <Container
                customWidth='100%'
                maxWidth='100%'
              >
                <FilterButton
                  isLoading={isLoading}
                  isWrap
                  items={filterButtons}
                />
              </Container>
              <Divider />
            </Column>
            <Container justifyContent='end'>
              {isLoading ? (
                <Container maxWidth='270px'>
                  <Backbone
                    borderRadius='20px'
                    height={32}
                  />
                </Container>
              ) : (
                <Pagination
                  {...pagination}
                  backgroundCurrent={getGlobalStyle(
                    '--color-alvi-primary-blue3'
                  )}
                  handleNextPage={handlePaginationNextPage}
                  handleOnClick={handleChangePage}
                  handlePrevPage={handlePaginationPrevPage}
                  redirect={false}
                />
              )}
            </Container>
            <Container
              gap='10px'
              grid
              gridTemplateColumns={4}
              margin='20px 0'
            >
              <CouponSimpleList
                coupons={paginatedCoupons}
                isLoading={isLoading}
                selectedFilter={selectedFilter}
              />
            </Container>
            <Container justifyContent='end'>
              {isLoading ? (
                <Container maxWidth='270px'>
                  <Backbone
                    borderRadius='20px'
                    height={32}
                  />
                </Container>
              ) : (
                <Pagination
                  {...pagination}
                  backgroundCurrent={getGlobalStyle(
                    '--color-alvi-primary-blue3'
                  )}
                  handleNextPage={handlePaginationNextPage}
                  handleOnClick={handleChangePage}
                  handlePrevPage={handlePaginationPrevPage}
                  redirect={false}
                />
              )}
            </Container>
          </Column>
        </Container>
      </BigScreen>

      <CouponDetail
        activeLoading={loadingCoupon.length > 0}
        coupon={selectedCoupon}
        isLoggedIn={isLoggedIn}
        onActive={handleDetailActive}
        onToggle={handleDetailToggle}
        open={!!selectedCoupon}
      />
    </Container>
  )
}
