import {
  ButtonStatus,
  Container,
  Row,
  ChipProps
} from '@smu-chile/pkg-unimarc-components'
import { MemoShelfAlvi } from '@smu-chile/pkg-unimarc-components/stories/organisms/ShelfAlvi/V1/ShelfAlvi'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { ShelvesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { statusPromotions } from 'shared/helpers'

export interface CouponDetailProductsProps {
  buttonLabel?: string
  buttonStatus?: ButtonStatus
  couponState?: boolean
  items: ShelvesData[]
  isLoading?: boolean
  isLoggedIn?: boolean
  onClickAdd?(payload?: unknown): void
  onClickItem?(payload?: unknown): void
  onClickRemove?(payload?: unknown): void
  validationStatus?: string
}

export const CouponDetailProducts = ({
  buttonLabel,
  buttonStatus,
  couponState,
  isLoading,
  isLoggedIn,
  items,
  onClickAdd,
  onClickItem,
  onClickRemove,
  validationStatus
}: CouponDetailProductsProps) => {
  const { innerWidth } = useMobile()

  const getCustomWidth = () => {
    return `${Math.floor(innerWidth / 300)}px`
  }

  const handleQuantityAdd = (payload: unknown) => {
    if (onClickAdd) {
      onClickAdd(payload)
    }
  }

  const handleShelfClick = (payload: unknown) => {
    if (onClickItem) {
      onClickItem(payload)
    }
  }

  const handleQuantityRemove = (payload: unknown) => {
    if (onClickRemove) {
      onClickRemove(payload)
    }
  }

  return (
    <Container isWrap>
      <Row
        isWrap
        justifyContent='center'
      >
        {items.map((item, index) => {
          const { isPromotion, offer, ppum, price } = statusPromotions(item)

          const { images, name, promotion, sellers } = item

          return (
            <MemoShelfAlvi
              {...item}
              buttonLabel={buttonLabel}
              buttonStatus={item?.buttonStatus ?? buttonStatus}
              couponState={couponState}
              customWidth={getCustomWidth()}
              handleOnClick={handleShelfClick}
              img={images[0]}
              importantWidth={'50'}
              inOffer={sellers[0]?.inOffer}
              isLoading={isLoading}
              isLoggedIn={isLoggedIn}
              isMobile
              isPromotion={isPromotion}
              key={index}
              likeListButtons={false}
              listPrice={sellers[0]?.listPrice}
              offer={offer}
              orientation={'vertical'}
              ppum={`(${ppum})`}
              ppumListPrice={`(${sellers[0]?.ppumListPrice})`}
              price={price}
              promotion={promotion as ChipProps}
              quantity={parseInt(`${item?.quantity}`)}
              quantityButtonProps={{
                ...item?.['quantityButtonProps'],
                handleAdd: handleQuantityAdd,
                handleRemove: handleQuantityRemove
              }}
              quantityButtonStyles={{
                maxHeight: '35px',
                maxWidth: '100%',
                sizeCircle: 25
              }}
              title={name}
              typePromotion={promotion?.['descriptionCode']}
              validationStatus={validationStatus}
            />
          )
        })}
      </Row>
    </Container>
  )
}
