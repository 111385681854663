import { getContentfulData } from '@smu-chile/pkg-unimarc-hooks'

export const ContentfulLoginBannerAssetIds: Record<string, string> = {
  [`master`]: '1FI9UQswEKFlLwNfFMsDGW',
  [`develop-new`]: '1FI9UQswEKFlLwNfFMsDGW',
  [`develop-web`]: '4OkaH2NSL6MIiWneSZoQ2I'
}

export async function getContentfulLoginBanner() {
  const contentful = await getContentfulData({
    options: {
      [`sys.id`]:
        ContentfulLoginBannerAssetIds[
          process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT ?? 'master'
        ]
    },
    reactQuery: {
      queryKey: 'loginAlviBanner'
    },
    type: 'assets'
  })

  const [loginBanner] = contentful?.items ?? []
  return loginBanner ?? null
}
