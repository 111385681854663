/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import {
  Button,
  Column,
  Container,
  MembershipHeaderAlvi,
  Picture,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  alviLevelsCF,
  setNextMembershipCycle,
  showButtons
} from 'shared/helpers'
import styles from './MembershipHeaderBrowse.module.css'
import { BenefitsModal } from 'components/BenefitsModal'

interface MembershipHeaderBrowseProps {
  data?: any
  goalType?: string
  levelGoal?: string
  shoppingGoal?: string
  userLevel?: string
  userName?: string
}

export const MembershipHeaderBrowse = ({
  data,
  goalType,
  levelGoal,
  shoppingGoal,
  userLevel,
  userName
}: MembershipHeaderBrowseProps) => {
  const [showBenefitsModal, setShowBenefitsModal] = useState(false)
  const [showNextBenefitsModal, setShowNextBenefitsModal] = useState(false)

  const { isMobile } = useMobile()
  const { headerData, buttonsData, benefitsImage, nextBenefitsImage } =
    alviLevelsCF(data, userLevel, setShowBenefitsModal, isMobile)

  const bodyHeightWithOutButtons = userLevel === 'clubalvi' ? '180px' : '300px'
  const bodyHeight = isMobile ? bodyHeightWithOutButtons : '190px'

  const handleClickBeMerchant = () => {
    buttonsData?.[0]?.onClick()
  }

  const handleGoalIconClick = () => {
    setShowNextBenefitsModal(true)
  }

  const handleBenefitsModalClose = () => {
    setShowBenefitsModal(false)
  }

  const handleNextBenefitsModalClose = () => {
    setShowNextBenefitsModal(false)
  }

  const sideChild = () => {
    if (!userLevel || userLevel === 'clubalvi' || userLevel === 'no level') {
      return (
        <Column
          alignItems='center'
          gap='16px'
        >
          <Text color='black'>
            ¡Accede a más beneficios, avanza al siguiente nivel!
          </Text>
          <Button
            background='#FFFFFF50'
            borderRadius={getGlobalStyle('--border-radius-full')}
            color='black'
            customPrevIcon={
              <Column
                margin='0 12px 0 0'
                width='30px'
              >
                <Picture
                  clickable='pointer'
                  height='26px'
                  src={`https:${buttonsData?.[0]?.icon}`}
                  width='auto'
                />
              </Column>
            }
            fontWeight='400'
            height='60px'
            label={buttonsData?.[0]?.text}
            onClick={handleClickBeMerchant}
            padding='0 29px'
            prevIconClickable='pointer'
          />
        </Column>
      )
    }

    return undefined
  }

  return (
    <>
      <Container
        customClassName={styles['levelHeader']}
        padding={isMobile ? '0 15px' : '0'}
      >
        <MembershipHeaderAlvi
          backboneProfileAlign={isMobile ? 'center' : 'left'}
          bodyHeight={bodyHeight}
          borderRadiusStyle='club'
          buttons={showButtons(isMobile, userLevel) && buttonsData}
          buttonsAligment='center'
          endDate={setNextMembershipCycle()}
          fontColor={headerData?.fontColor}
          goalImage={headerData?.goalImage}
          goalType={goalType}
          isMobile={isMobile}
          levelGoal={levelGoal}
          limitType='inner'
          mainBackgroundColor={headerData?.backgroundColor}
          mainContentAlign='center'
          marginTop={isMobile ? '8px' : '32px'}
          onGoalIconClick={handleGoalIconClick}
          progressBar={headerData?.progressBar}
          secondaryBackgroundColor={headerData?.backgroundColor}
          shoppingGoal={shoppingGoal}
          showBackBoneButtons={false}
          sideChildFull={!isMobile && sideChild()}
          type={isMobile ? 'full' : 'half'}
          userIcon={headerData?.icon}
          userLevel={headerData?.level}
          userName={userName}
        />
      </Container>

      <BenefitsModal
        imageSrc={`https:${benefitsImage}`}
        isOpen={showBenefitsModal}
        onClose={handleBenefitsModalClose}
      />

      <BenefitsModal
        description={
          <>
            ¡Buenas noticias! Estos son los beneficios
            <br />
            que te esperan si subes de nivel
          </>
        }
        imageSrc={`https:${nextBenefitsImage}`}
        isOpen={showNextBenefitsModal}
        onClose={handleNextBenefitsModalClose}
        title='Próximo nivel'
      />
    </>
  )
}
