import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  dataLayerUniqueTypeEvent,
  productClickTag,
  shelvesImpression
} from '@smu-chile/pkg-unimarc-hooks'
import { formatProductUrl } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/formatProductUrl'
import { ShelvesDataAlvi } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICart'
import { IIntelligenceSearch } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { ISearchSuggestions } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ISearchSuggestions'
import { ITopSearches } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ITopSearches'
import { RefObject } from 'react'

const isVisibleFunction = (isVisible: boolean) => {
  const backgroundSearch = isVisible ? 'white' : 'transparent'
  const borderSearch = isVisible ? '1px solid #C4C4C4' : '1px solid white'
  return { backgroundSearch, borderSearch }
}

export interface IResult {
  topSearches?: ITopSearches
  searchSuggestions?: ISearchSuggestions
  intelligenceSearch?: IIntelligenceSearch
}
interface ISearchProps {
  inputAdornmentRef: RefObject<HTMLInputElement>
  isMobile: boolean
  isVisible: boolean
  isVisibleClearIcon: boolean
  result?: IResult
  shelvesData?: ShelvesDataAlvi[]
  value: string
  userId?: string
  handleAdd?: ({ itemId }) => void
  handleChange?: (e: unknown, { itemId }) => void
  handleChangeSearch: (value: string) => void
  handleOnClick?: ({ itemId }) => void
  handleRemove?: ({ itemId }) => void
  setIsVisible: (value: boolean) => void
  setIsVisibleClearIcon: (value: boolean) => void
  setValue: (value: string) => void
}

export const searchProps = ({
  handleAdd,
  handleChange,
  handleChangeSearch,
  handleOnClick,
  handleRemove,
  inputAdornmentRef,
  isMobile,
  isVisible,
  isVisibleClearIcon,
  result,
  setIsVisible,
  setIsVisibleClearIcon,
  setValue,
  shelvesData,
  value,
  userId
}: ISearchProps) => {
  const { backgroundSearch, borderSearch } = isVisibleFunction(isVisible)
  const widthQuantityButton = isMobile ? '120px' : '150px'
  const site = 'Alvi'
  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  // Get an array of items that return the search
  const suggestionsDataTopSearch = result?.topSearches?.data?.searches
    ?.slice(0, 5)
    ?.map((item, index) => {
      return {
        text: `${index + 1}. ${capitalizeFirstLetter(item?.term)}`,
        onClick: () => {
          return handleOnclickHeadSearch(item)
        }
      }
    })

  // Get an array of suggestions that return the search
  const suggestionsDataSearch = result?.searchSuggestions?.data?.searches?.map(
    (item) => {
      return {
        text: `${item?.term}`,
        onClick: () => {
          return handleOnclickHeadSearch(item)
        }
      }
    }
  )

  // Get an array of items with matches that return the search and return the structure required
  const intelligentData = shelvesData?.map((item) => {
    const maxQuantity =
      item?.cartLimit > 0
        ? item?.cartLimit
        : item?.sellers?.[0]?.availableQuantity
    return {
      ...item,
      img: item?.images[0],
      brand: item?.brand.toLowerCase(),
      inOffer: item?.sellers[0]?.inOffer,
      itemId: item?.itemId,
      listPrice: item?.sellers[0]?.listPrice,
      maxQuantity,
      measurementUnit: item?.measurementUnit,
      price: item?.sellers[0]?.price,
      product: item,
      productUrl: formatProductUrl(item?.detailUrl),
      title: item?.name,
      onClickProduct: () => {
        productClickTag({
          product: item,
          isSearch: true,
          site: site,
          userId: userId
        })
      }
    }
  })

  // 'view_item_list event tags
  const shelvesSearchImpressions = (shelves) => {
    if (!shelves[0]?.ref) return
    shelvesImpression({
      isSearch: true,
      parent: shelves[0].ref.parentNode.parentNode,
      shelves,
      site,
      userId
    })
  }

  const headSearchData = () => {
    const isValid = intelligentData?.length > 0
    const suggestionsTitle = isValid ? 'Sugerencias' : 'Más buscados'
    const suggestionsData = isValid
      ? suggestionsDataSearch
      : suggestionsDataTopSearch

    return { suggestionsTitle, suggestionsData }
  }

  const handleCloseSearch = () => {
    setIsVisible(false)
    setIsVisibleClearIcon(false)
  }

  const handleClearInputSearch = () => {
    if (inputAdornmentRef.current && inputAdornmentRef.current.children[0]) {
      const input = inputAdornmentRef.current.children[0]
        .children[0] as HTMLInputElement
      input.value = ''
    }

    setValue('')
    setIsVisible(false)
  }

  const getUrlSearch = (term) => {
    return `/search?q=${term}`
  }

  const handleOnclickHeadSearch = ({ term }) => {
    window.location.href = getUrlSearch(term)
    dataLayerUniqueTypeEvent({
      event: 'search',
      search_term: term,
      search_type: intelligentData?.length > 0 ? 'Sugeridos' : 'Top Search'
    })
    handleClearInputSearch()
    setIsVisible(false)
  }

  const handleKeywordKeyPressSearch = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key == 'Enter') {
      handleOnclickSearch()
      setIsVisible(false)
    }
  }

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value

    if (!isMobile) {
      if (targetValue.length === 1 || targetValue.length === 2) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    } else {
      setIsVisible(true)
    }
    setIsVisibleClearIcon(targetValue.length > 0)
    // search
    handleChangeSearch(targetValue)
    setValue(targetValue)
  }

  const handleOnclickSearch = () => {
    window.location.href = getUrlSearch(value)

    if (inputAdornmentRef.current && inputAdornmentRef.current.children[0]) {
      const input = inputAdornmentRef.current.children[0]
        .children[0] as HTMLInputElement
      input.blur()
    }

    dataLayerUniqueTypeEvent({
      event: 'search',
      search_term: value,
      search_type: 'Lupa'
    })
    handleClearInputSearch()
    setIsVisible(false)
  }

  return {
    backgroundSearch,
    borderSearch,
    clearButton: isVisibleClearIcon,
    dropDownSearch: {
      bodySearch: {
        shelfSearchColor: 'blue',
        backgroundGradientButton: getGlobalStyle(
          '--color-alvi-primary-blue-gradient'
        ),
        handleSearchImpressions: shelvesSearchImpressions,
        data: intelligentData,
        quantityButtonProps: {
          quantityButtonStyles: {
            maxHeight: '30px',
            maxWidth: widthQuantityButton,
            minWidth: widthQuantityButton,
            sizeCircle: 22
          },
          handleAdd,
          handleRemove,
          handleChange
        },
        handleOnClickShelfSearch: handleOnClick
      },
      headSearch: headSearchData(),
      isLoadingSearch:
        result?.intelligenceSearch?.isLoading || result?.topSearches?.isLoading,
      valueInputSearch: value
    },
    isVisibleDropDownSearch: isVisible,
    inputAdornmentRef,
    iconArrowSize: 18,
    searchPlaceholder: '¿Qué quieres comprar hoy?',
    iconWidth: isMobile ? '16' : '14',
    valueInputSearch: value,
    onChangeSearch: handleOnChangeSearch,
    onClearInput: handleClearInputSearch,
    onClickSearch: handleOnclickSearch,
    onCloseSearch: handleCloseSearch,
    onKeyPressSearch: handleKeywordKeyPressSearch
  }
}
