import { ChangeEvent, useMemo, useRef, useState } from 'react'
import {
  Column,
  Container,
  Icon,
  Link,
  LoginFormDirty,
  LoginFormError,
  LoginFormValues,
  LoginModal,
  LoginModalProps,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  formatRut,
  validateRut
} from '@smu-chile/pkg-unimarc-components/utils/formatRut'
import {
  getRegisterUrl,
  postAlviLogin,
  useTryAuthorize
} from '@smu-chile/pkg-unimarc-hooks'
import HttpError from '@smu-chile/pkg-unimarc-hooks/shared/errors/HttpError'
import { checkClientNotFound, useLoginModalBrowseTries } from './helpers'
import { LoginTooltip } from './LoginTooltip'
import { cleanRut } from './helpers/cleanRut'
import { useRouter } from 'next/router'

export interface LoginModalBrowseProps extends LoginModalProps {
  imageSrc: string
}

interface LoginFormProps {
  dirty?: LoginFormDirty
  errors?: LoginFormError
  loading?: boolean
}

const MAX_LOGIN_TRIES = 4
const LOGIN_RETRY_TIME_MS = 10_000

export const LoginModalBrowse = ({
  imageSrc,
  onClose,
  ...props
}: LoginModalBrowseProps): React.ReactElement => {
  const router = useRouter()
  const [formValues, setFormValues] = useState<LoginFormValues>({
    rut: '',
    password: ''
  })
  const loginTries = useRef(0)
  const loginLastTry = useRef(new Date())

  const [formState, setFormState] = useState<LoginFormProps>({
    dirty: {},
    errors: {}
  })

  const [errorMessageId, setErrorMessageId] = useState('')

  const { tick: tickLoginTry } = useLoginModalBrowseTries({
    limit: MAX_LOGIN_TRIES,
    retry: LOGIN_RETRY_TIME_MS
  })

  const { refetch: tryAuthorize } = useTryAuthorize({
    reactQuery: {
      enabled: false
    },
    rut: formValues.rut
  })

  const setError = (
    field: keyof LoginFormError,
    value: LoginFormError[keyof LoginFormError]
  ) => {
    setFormState((prev) => {
      return {
        ...prev,
        errors: {
          ...prev.errors,
          [field]: value
        },
        dirty: {
          ...prev.dirty,
          [field]: false
        }
      }
    })
  }

  const disableSubmit = useMemo(() => {
    if (
      formState.errors.rut ||
      formState.loading ||
      formValues.rut === '' ||
      formValues.password === ''
    ) {
      return true
    }

    return false
  }, [formState.errors, formState.loading, formValues.password, formValues.rut])

  const setLoading = (value: boolean) => {
    setFormState((prev) => {
      return {
        ...prev,
        loading: value
      }
    })
  }

  const resetFormState = () => {
    const newFormState = {
      dirty: {},
      errors: {}
    }
    setFormState(newFormState)
    return newFormState
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      const { name } = event.target
      let { value } = event.target

      if (name === 'rut') {
        value = cleanRut(value)
        value = formatRut(value)

        setFormState({
          ...formState,
          errors: {
            [name]:
              value.trim() !== ''
                ? !validateRut(value) && 'Ingrese un RUT válido.'
                : null
          }
        })
      }

      setFormValues({
        ...formValues,
        [name]: value
      })
    }

    if (!formState.dirty[event.target.name]) {
      setFormState({
        ...formState,
        dirty: {
          ...formState.dirty,
          [event.target.name]: true
        }
      })
    }
  }

  const handleSubmit = async () => {
    const isValidRut = validateRut(formValues.rut)
    if (!isValidRut) {
      setError('rut', 'Rut inválido')
      return
    }

    resetFormState()
    setLoading(true)

    let tic = 1

    try {
      const { data } = await tryAuthorize()

      checkClientNotFound(data)

      tic = tickLoginTry()

      loginLastTry.current = new Date()
      loginTries.current++

      const response = await postAlviLogin(formValues.rut, formValues.password)
      if (response.status >= 300)
        throw new HttpError(response.statusText, response.status, {})

      await onClose?.()
      await router.push({ query: {} }, undefined)
      router.reload()
    } catch (error) {
      if (error.message === 'client-not-found') {
        setErrorMessageId('client-not-found-message')
        setLoading(false)
        return
      }

      const httpError = error as HttpError
      switch (httpError.status) {
        case 200:
          break
        case 429:
          setErrorMessageId('block-login-tries-message')
          break
        default:
          if (tic < 3) {
            setErrorMessageId('invalid-credential-message')
          } else if (tic < 4) {
            setErrorMessageId('three-login-tries-message')
          } else {
            setErrorMessageId('block-login-tries-message')
          }
          break
      }
    }
    setLoading(false)
  }

  const handleClose = () => {
    setFormValues({
      password: '',
      rut: ''
    })
    resetFormState()
    onClose?.()
  }

  const handleLoginModalGuestClick = () => {
    if (onClose) onClose()
  }

  const handleLoginModalRegisterClick = () => {
    window.location.href = getRegisterUrl()
  }

  const handleLoginTooltipClose = () => {
    setErrorMessageId('')
  }

  return (
    <LoginModal
      {...formState}
      {...props}
      disableSubmit={disableSubmit}
      imageSrc={imageSrc}
      maxWidth='862px'
      onChange={handleChange}
      onClose={handleClose}
      onGuestClick={handleLoginModalGuestClick}
      onRegisterClick={handleLoginModalRegisterClick}
      onSubmit={handleSubmit}
      prependSlot={
        <>
          {errorMessageId !== '' && (
            <LoginTooltip
              customChildren={
                <Container>
                  <Row
                    alignItems='center'
                    gap={8}
                  >
                    <Column maxWidth='max-content'>
                      <Icon
                        color='var(--color-feedback-error-light)'
                        customSize={20}
                        name='InfoFill'
                      />
                    </Column>
                    <Column margin='0 10px 0 0'>
                      {errorMessageId === 'not-found-user-message' && (
                        <>
                          <Text
                            color='black'
                            display='block'
                            fontSize='md'
                            fontWeight='medium'
                          >
                            Usuario no registrado, te invitamos a hacerlo{' '}
                            <Link
                              color='black'
                              fontSize='md'
                              fontWeight='semibold'
                              href='#'
                              underline='always'
                            >
                              aquí
                            </Link>
                            .
                          </Text>
                        </>
                      )}
                      {errorMessageId === 'invalid-credential-message' && (
                        <Text
                          color='black'
                          display='block'
                          fontSize='md'
                          fontWeight='medium'
                        >
                          Tu RUT o contraseña son incorrectos. Por favor,
                          intenta de nuevo.
                        </Text>
                      )}
                      {errorMessageId === 'three-login-tries-message' && (
                        <Text
                          color='black'
                          display='block'
                          fontSize='md'
                          fontWeight='medium'
                        >
                          ¿No recuerdas tu contraseña? Recupera tu contraseña{' '}
                          <Link
                            color='black'
                            fontSize='md'
                            fontWeight='semibold'
                            href='#'
                            onClick={props.onForgotPasswordClick}
                            underline='always'
                          >
                            aquí
                          </Link>{' '}
                          o presionando “Olvidé mi contraseña”.
                        </Text>
                      )}
                      {errorMessageId === 'block-login-tries-message' && (
                        <Text
                          color='black'
                          display='block'
                          fontSize='md'
                          fontWeight='medium'
                        >
                          Has superado la cantidad máxima de intentos. Recupera
                          tu cuenta{' '}
                          <Link
                            color='black'
                            fontSize='md'
                            fontWeight='semibold'
                            href='#'
                            onClick={props.onForgotPasswordClick}
                            underline='always'
                          >
                            aquí
                          </Link>
                          .
                        </Text>
                      )}
                      {errorMessageId === 'client-not-found-message' && (
                        <Text
                          color='black'
                          display='block'
                          fontSize='md'
                          fontWeight='medium'
                        >
                          Usuario no registrado, te invitamos a hacerlo{' '}
                          <Link
                            color='black'
                            fontSize='md'
                            fontWeight='semibold'
                            href={getRegisterUrl()}
                            onClick={props.onRegisterClick}
                            underline='always'
                          >
                            aquí
                          </Link>
                          .
                        </Text>
                      )}
                    </Column>
                  </Row>
                </Container>
              }
              onClose={handleLoginTooltipClose}
              variant='error'
            />
          )}
        </>
      }
      site='alvi'
      values={formValues}
    />
  )
}
