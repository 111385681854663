import { IAlviCouponWithActivation } from '@smu-chile/pkg-unimarc-hooks/hooks/useCouponsAlvi'
import { statusMap } from 'components/CouponListPage'
import { useMemo } from 'react'

export type UseCouponsCarouselProps = {
  coupons?: Array<IAlviCouponWithActivation>
}

/**
 * Custom hook to filter and transform coupons for use in a carousel.
 *
 * @param {Object} props - The properties to configure the hook.
 * @param {Array<IAlviCouponWithActivation>} [props.coupons] - An optional array of coupons to be processed.
 *
 * @returns {Array<Object>} - An array of transformed coupon objects, filtered by their validity and limited to 8 items.
 *
 * @example
 * const carouselCoupons = useCouponsCarousel({ coupons });
 * console.log(carouselCoupons);
 */
export const useCouponsCarousel = ({ coupons }: UseCouponsCarouselProps) => {
  const isValidStatus = (coupon: IAlviCouponWithActivation): boolean => {
    return coupon.status !== statusMap['used']
  }

  const transformCoupon = (coupon: IAlviCouponWithActivation) => {
    return {
      description: coupon.description,
      id: coupon.id,
      image: coupon.couponImage,
      lead: coupon.lead,
      loading: coupon.isActivating,
      status: statusMap[coupon.status],
      subtitle: coupon.subtitle,
      title: coupon.title
    }
  }

  const carouselCoupons = useMemo(() => {
    return coupons.filter(isValidStatus).slice(0, 8).map(transformCoupon)
  }, [coupons])

  return carouselCoupons
}
