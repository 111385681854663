import { useState } from 'react'
import HttpError from '@smu-chile/pkg-unimarc-hooks/shared/errors/HttpError'

export interface UseLoginModalBrowseTriesProps {
  limit?: number
  retry?: number
}

export const useLoginModalBrowseTries = ({
  limit,
  retry
}: UseLoginModalBrowseTriesProps) => {
  const [last, setLast] = useState<number>(null)
  const [tries, setTries] = useState<number>(0)

  const tick = () => {
    const now = Date.now()
    const dif = now - last
    const tic = dif < retry ? tries + 1 : 1

    setTries(tic)

    if (limit < tic) {
      throw new HttpError('Has superado los intentos para ingresar', 429, {})
    } else {
      setLast(now)
    }

    return tic
  }

  return {
    tick
  }
}
