import { useEffect, useState } from 'react'
import {
  ButtonStatus,
  QuantityButtonProps,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { MemoShelfAlvi } from '@smu-chile/pkg-unimarc-components/stories/organisms/ShelfAlvi/V1/ShelfAlvi'
import {
  productClickTag,
  shelvesImpression,
  useAlviSession,
  useEvents,
  useUser,
  useUpdateFromShelfAlvi,
  useCart
} from '@smu-chile/pkg-unimarc-hooks'

import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { getDataPromotion, statusPromotions } from '../../shared/helpers'
import { formatProductUrl } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/formatProductUrl'
import { redirect } from 'shared/utils/redirect'
import styles from './Shelves.module.css'

export interface Data extends Product, AvailableProduct {
  buttonStatus?: ButtonStatus
  isDisabled?: boolean
  isLoading?: boolean
  quantity?: string | number
}
interface ShelvesProps {
  customWidthQuantityButton?: string
  dataPromotions?: unknown[]
  isMobile: boolean
  orientation?: 'horizontal' | 'vertical'
  padding?: string
  products: AvailableProduct[] | Product[] | Data[]
  quantityButtonProps?: Omit<QuantityButtonProps, 'value'>
  handleOnClick?: (props?: { itemId: string }) => void
}

export const Shelves = ({
  customWidthQuantityButton = '100%',
  dataPromotions,
  isMobile,
  orientation,
  padding,
  products
}: ShelvesProps): React.ReactElement => {
  const user = useUser()
  const { isLoggedIn } = useAlviSession()
  const { data } = useCart()
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const {
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove,
    shelvesData
  } = useUpdateFromShelfAlvi({
    products,
    isOrderFormLoading,
    userId: user?.userId
  })
  const impressableShelves = []
  const maxHeightQuantityButton = isMobile ? '30px' : '35px'
  const maxWidthQuantityButton = isMobile ? '170px' : '213px'
  const validationStatus = user?.['validationStatus'] ?? 'logout'
  const site = 'Alvi'

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  useEffect(() => {
    if (impressableShelves.length === shelvesData?.length) {
      shelvesImpression({
        orderForm: data,
        shelves: impressableShelves,
        site: site,
        userId: user?.['userId']
      })
    }
  }, [impressableShelves, shelvesData, data, user])
  return (
    <Row
      isWrap
      padding={padding}
    >
      {shelvesData &&
        shelvesData.map((product, index) => {
          const { isPromotion, price, ppum, offer } = statusPromotions(product)
          const promotion = getDataPromotion(
            dataPromotions,
            product?.promotion?.['name'] ?? ''
          )
          const urlName = formatProductUrl(product.detailUrl)
          const maxQuantity =
            product?.cartLimit > 0
              ? product?.cartLimit
              : product?.sellers[0]?.availableQuantity
          const isOutOfStock = product?.['stock'] ? '' : styles.shelf_disabled
          const promoData = { product, site: site, userId: user?.['userId'] }
          return (
            <MemoShelfAlvi
              key={product?.productId}
              {...product}
              brand={product?.brand}
              catchShelfRef={(ref) => {
                if (ref)
                  impressableShelves.push({ ref, product, position: index + 1 })
              }}
              customClassName={`${styles.shelf} ${isOutOfStock}`}
              customWidthQuantityButton={customWidthQuantityButton}
              format={product?.format}
              handleOnClick={handleOnClick}
              img={product?.images[0]}
              inOffer={product?.sellers[0]?.inOffer}
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
              isPromotion={isPromotion}
              likeListButtons={false}
              listPrice={product?.sellers[0]?.listPrice}
              offer={offer}
              onClickProduct={() => {
                productClickTag(promoData)
              }}
              onRedirect={() => {
                productClickTag(promoData)
                redirect(urlName)
              }}
              orientation={orientation}
              ppum={`(${ppum})`}
              ppumListPrice={`(${product?.sellers[0]?.ppumListPrice})`}
              price={price}
              promotion={promotion ?? product['promotionalTag'] ?? {}}
              quantityButtonProps={{
                ...product?.['quantityButtonProps'],
                maxQuantity,
                handleAdd,
                handleChange,
                handleRemove
              }}
              quantityButtonStyles={{
                fontSize: 'md',
                maxHeight: maxHeightQuantityButton,
                maxWidth: maxWidthQuantityButton,
                sizeCircle: 25
              }}
              title={product?.name}
              typePromotion={product?.promotion?.['descriptionCode']}
              url={urlName}
              validationStatus={validationStatus}
            />
          )
        })}
    </Row>
  )
}
