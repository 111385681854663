import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import {
  generateBreadcrumbJoin,
  useCart,
  useProgressiveLoading,
  useUserAlvi,
  visiblePromosScroll
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Container,
  Column,
  Spacer,
  HtmlRenderProps
} from '@smu-chile/pkg-unimarc-components'
import { IRenderProductsCarouselProps } from 'components/ProductsCarousel/RenderProductsCarousel'
import { useRouter } from 'next/router'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IShowCaseWebProps } from './components/ShowCaseWeb'
import { IBannersWebProps } from './components/BannersWeb'
import { PropsHome } from 'shared/interfaces/Home'
import { BackboneHome } from './components/Backbone'
import { BannersWebItemProps } from './components/BannersWebItem'

const ShowCaseWeb = dynamic<IShowCaseWebProps>(
  () => {
    return import('./components/ShowCaseWeb').then((mod) => {
      return mod.ShowCaseWeb
    })
  },
  { ssr: true }
)

const BannersWeb = dynamic<IBannersWebProps>(
  () => {
    return import('./components/BannersWeb').then((mod) => {
      return mod.BannersWeb
    })
  },
  { ssr: true }
)

const BannersWebItem = dynamic<BannersWebItemProps>(
  () => {
    return import('./components/BannersWebItem').then((mod) => {
      return mod.BannersWebItem
    })
  },
  { ssr: true }
)

const RenderProductsCarousel = dynamic<IRenderProductsCarouselProps>(
  () => {
    return import('components/ProductsCarousel/RenderProductsCarousel').then(
      (mod) => {
        return mod.RenderProductsCarousel
      }
    )
  },
  { ssr: false }
)

const RenderStringHtml = dynamic<HtmlRenderProps>(
  () => {
    return import('@smu-chile/pkg-unimarc-components').then((mod) => {
      return mod.RenderStringHtml
    })
  },
  { ssr: true }
)

export const Home = ({ data, isMobile }: PropsHome) => {
  const router = useRouter()
  const orderForm = useCart()
  const { data: user } = useUserAlvi() || {}
  const userId = user?.userId
  const userLevel = user?.level
  const isFallback = router.isFallback
  const site = 'Alvi'
  const spacerSize = isMobile ? 4 : 16
  let undefinedContent = 0
  const customTitles = {
    h2: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-xs')
        : getGlobalStyle('--font-size-titles-lg')
    },
    h3: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-2xs')
        : getGlobalStyle('--font-size-titles-md')
    }
  }
  const mobilePadding = isMobile ? '0 12px' : '0'
  const totalEntries = Object.entries(data || {}).length
  const loadAndIncrementBy = isMobile ? 4 : 5
  const isServer = typeof window === 'undefined'

  // custom hook to load more content progressively
  const { visibleEntries } = useProgressiveLoading({
    totalEntries,
    initialCount: loadAndIncrementBy,
    incrementBy: loadAndIncrementBy,
    rootMargin: isMobile ? '100px' : '1000px',
    threshold: 0.1
  })

  const renderContent = (content, index) => {
    const contentType = content?.[0]
    const contentData = content?.[1]
    if (/showcaseWeb/i.test(contentType)) {
      return (
        <Column
          alignItems='center'
          justifyContent='center'
          key={index}
          padding={mobilePadding}
        >
          <ShowCaseWeb
            cfIndex={index + 1 - undefinedContent}
            data={contentData?.['slider']}
            orderForm={orderForm}
            site={site}
            userId={userId}
          />
        </Column>
      )
    }

    if (/featureContentBannersWeb/i.test(contentType)) {
      return (
        <Column
          alignItems='center'
          justifyContent='center'
          key={index}
          width='100'
        >
          <BannersWeb
            cfIndex={index + 1 - undefinedContent}
            data={contentData}
            isMobile={isMobile}
            orderForm={orderForm}
            site={site}
            titleSizes={customTitles}
            userId={userId}
            userLevel={userLevel}
          />
        </Column>
      )
    }

    if (/featureContentItem/i.test(contentType)) {
      return (
        <Column
          alignItems='center'
          justifyContent='center'
          key={index}
          width='100'
        >
          <BannersWebItem content={contentData} />
        </Column>
      )
    }

    if (/productsCarouselWeb/i.test(contentType)) {
      return (
        <Container
          alignItems='center'
          justifyContent='center'
          key={index}
        >
          <RenderProductsCarousel
            content={contentData}
            customTitles={customTitles}
            index={index}
          />
        </Container>
      )
    }

    if (/term/i.test(contentType)) {
      return (
        <RenderStringHtml
          htmlString={contentData?.html}
          key={index}
        />
      )
    }

    undefinedContent += 1
    return null
  }

  const entries = Object.entries(data || {})
  const entriesToRender = isServer ? entries : entries.slice(0, visibleEntries)

  useEffect(() => {
    if (user && orderForm.data) {
      generateBreadcrumbJoin([])
      visiblePromosScroll({ site, userId, orderForm: orderForm?.data })
    }
  }, [user, orderForm])

  return (
    <>
      {isFallback ? (
        <BackboneHome />
      ) : (
        <Column
          background={getGlobalStyle('--color-alvi-neutral-gray-white')}
          tagName='main'
        >
          <Column alignItems='center'>
            <Spacer.Horizontal size={spacerSize} />

            {entriesToRender.map(renderContent)}
          </Column>
          <Container
            height='1'
            id='sentinel'
            width='100%'
          />
        </Column>
      )}
    </>
  )
}
