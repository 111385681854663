import { useEffect, useRef } from 'react'
import {
  Container,
  Coupon,
  CouponProps
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  getCouponName,
  initCouponsImpression,
  isValidArrayWithData,
  TaggableCoupon
} from '@smu-chile/pkg-unimarc-hooks'
import { CouponSimpleListLoader } from './CouponListLoader/CouponListLoader'

export interface CouponSimpleListProps {
  coupons: CouponProps[]
  isLoading?: boolean
  selectedFilter?: string
}

export const CouponSimpleList = ({
  coupons = [],
  isLoading,
  selectedFilter
}: CouponSimpleListProps) => {
  const container = useRef()
  const couponsToActivate = useRef<TaggableCoupon<HTMLButtonElement>[]>([])

  const assignCouponRef = (
    ref: HTMLButtonElement | HTMLAnchorElement,
    coupon: CouponProps,
    index: number
  ) => {
    const taggableCoupon: TaggableCoupon = {
      ref,
      id: coupon.id,
      index: `${coupon.index + 1}`,
      name: getCouponName(coupon)
    }
    if (coupon.status === undefined && ref instanceof HTMLButtonElement) {
      couponsToActivate.current[index] =
        taggableCoupon as TaggableCoupon<HTMLButtonElement>
    }
  }

  const referenceCoupon = (coupon: CouponProps, index: number) => {
    return (ref: HTMLButtonElement | HTMLAnchorElement) => {
      return assignCouponRef(ref, coupon, index)
    }
  }

  useEffect(() => {
    if (!selectedFilter)
      return () => {
        return
      }
    const removeEventListener = initCouponsImpression({
      coupons: couponsToActivate.current,
      creativeName: 'boton-activar',
      eventKey: 'boton-activar',
      locationId: `shelf-cupones-${selectedFilter}`,
      couponType: selectedFilter
    })

    couponsToActivate.current = []
    return removeEventListener
  }, [couponsToActivate.current, selectedFilter])

  if (isLoading) {
    return <CouponSimpleListLoader />
  }

  return (
    <>
      {isValidArrayWithData(coupons) &&
        coupons.map((coupon, index) => {
          return (
            <Container
              alignItems='center'
              key={index}
              ref={container}
            >
              <BigScreen>
                <Coupon
                  activeButtonRef={referenceCoupon(coupon, index)}
                  bottomContainerProps={{
                    padding: '0 20px 20px'
                  }}
                  buttonHeight='40px'
                  compact
                  customHeight='100%'
                  descriptionProps={{
                    fontSize: 'md',
                    fontWeight: 'semibold'
                  }}
                  leadProps={{
                    fontSize: 'md'
                  }}
                  outlined
                  shadowned={false}
                  site='alvi'
                  titleProps={{
                    fontSize: 'xl',
                    fontWeight: 'semibold'
                  }}
                  topContainerProps={{
                    padding: '16px 24px 24px'
                  }}
                  usedButtonText='Cupón utilizado'
                  viewProductsRef={referenceCoupon(coupon, index)}
                  viewProductsTextColor={getGlobalStyle(
                    '--color-alvi-neutral-black'
                  )}
                  {...coupon}
                />
              </BigScreen>

              <SmallScreen>
                <Container
                  alignItems='stretch'
                  customHeight='100%'
                >
                  <Coupon
                    activeButtonRef={referenceCoupon(coupon, index)}
                    buttonHeight='32px'
                    compact
                    customHeight='100%'
                    site='alvi'
                    titleProps={{
                      fontWeight: 'semibold'
                    }}
                    viewProductsTextColor={getGlobalStyle(
                      '--color-alvi-neutral-black'
                    )}
                    {...coupon}
                  />
                </Container>
              </SmallScreen>
            </Container>
          )
        })}
    </>
  )
}
