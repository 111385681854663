import {
  ConfirmCodeModal,
  ConfirmCodeModalProps
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export type ConfirmCodeModalBrowseProps = ConfirmCodeModalProps

export const ConfirmCodeModalBrowse = (props: ConfirmCodeModalBrowseProps) => {
  return (
    <>
      <BigScreen>
        <ConfirmCodeModal
          codeInputProps={{
            outlined: true,
            shadowned: false,
            variant: 'gray'
          }}
          headerDividerColor={getGlobalStyle('--color-neutral-gray-divider')}
          headerDividerSize='3xs'
          headerProps={{
            prependIcon: 'Back4'
          }}
          retryLinkColor={getGlobalStyle('--color-alvi-secondary-green')}
          retryText='¿No recibiste el código?'
          retryTime={30}
          tip='Asegúrate de revisar las carpetas de promociones, spam o correo no deseado.'
          tipBorderColor={getGlobalStyle('--color-neutral-gray-divider')}
          tipBorderStyle='solid'
          tipBorderWidth={getGlobalStyle('--border-width-2xs')}
          tipBoxShadow='none'
          tipIcon='InfoFillThin'
          tipIconColor={getGlobalStyle('--color-feedback-info')}
          title='Recuperación de contraseña'
          {...props}
        />
      </BigScreen>

      <SmallScreen>
        <ConfirmCodeModal
          borderRadiusLess
          closeContainerSize='44px'
          codeInputProps={{
            outlined: true,
            shadowned: false,
            variant: 'gray'
          }}
          fullScreen
          fullScreenMinHeight='100%'
          headerIconHoverable={false}
          headerProps={{
            boxShadow: getGlobalStyle('--box-shadow-2xs'),
            dividerSize: 'none',
            position: 'sticky',
            rowMinHeight: '52px',
            rowPadding: '0 4px',
            prependIcon: 'Back4'
          }}
          justify='start'
          retryLinkColor={getGlobalStyle('--color-alvi-secondary-green')}
          retryText='¿No recibiste el código?'
          retryTime={30}
          tip='Asegúrate de revisar las carpetas de promociones, spam o correo no deseado.'
          tipBorderColor={getGlobalStyle('--color-neutral-gray-divider')}
          tipBorderStyle='solid'
          tipBorderWidth={getGlobalStyle('--border-width-2xs')}
          tipBoxShadow='none'
          tipIcon='InfoFillThin'
          tipIconColor={getGlobalStyle('--color-feedback-info')}
          title='Recuperar contraseña'
          titleLineHeight='17px'
          titleSize='lg'
          {...props}
        />
      </SmallScreen>
    </>
  )
}
