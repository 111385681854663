import { useRef } from 'react'
import { Container, Tooltip } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useOutside } from '@smu-chile/pkg-unimarc-components/shared/hooks'
import { useAfterOf } from '@smu-chile/pkg-unimarc-hooks'

export interface LoginTooltipProps {
  customChildren?: React.ReactElement
  onClose?: VoidFunction
  variant?: 'error' | 'info'
}

export const LoginTooltip = ({
  customChildren,
  onClose,
  variant
}: LoginTooltipProps) => {
  const tooltipRef = useRef()

  useAfterOf({
    ms: 5000,
    onFinish: onClose
  })

  useOutside({
    onClick: onClose,
    ref: tooltipRef
  })

  const customBackground = {
    error: getGlobalStyle('--color-feedback-error-bg'),
    info: getGlobalStyle('--color-feedback-info-bg')
  }

  return (
    <Container
      position='relative'
      ref={tooltipRef}
    >
      <Tooltip
        alignAllItems='center'
        background={customBackground[variant]}
        borderRadius='var(--border-radius-2sm)'
        boxShadow='var(--box-shadow-3md)'
        closeColor='var(--color-icons-black)'
        closeIcon
        containerProps={{
          zIndex: '2'
        }}
        display='flex'
        margin='8px 0 0'
        maxWidth='310px'
        onClick={onClose}
        padding='12px 8px'
        position='absolute'
        positionContainer='sticky'
        right='auto'
        textComponent={customChildren}
        type='bottom'
        width='343px'
      />
    </Container>
  )
}
