import { Container, Icon, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ConfirmWrapModal, ConfirmWrapModalProps } from './ConfirmWrapModal'

export type ResetPasswordConfirmModalProps = ConfirmWrapModalProps

export const ResetPasswordConfirmModal = (
  props: ResetPasswordConfirmModalProps
) => {
  return (
    <ConfirmWrapModal
      title={
        <Text
          fontSize='xl'
          fontWeight='semibold'
          textAlign='center'
        >
          Recuperación
          <br />
          de contraseña exitoso
        </Text>
      }
      width='391px'
      {...props}
    >
      <Container
        justifyContent='center'
        padding='0.25rem'
      >
        <Icon
          color={getGlobalStyle('--color-feedback-success-light')}
          customSize={28}
          name='CheckCircle'
        />
      </Container>

      <Text
        display='block'
        fontSize='md'
        fontWeight='semibold'
        textAlign='center'
      >
        Ya puedes seguir disfrutando de tus
        <br /> compras en Club Alvi.
      </Text>
    </ConfirmWrapModal>
  )
}
