import {
  Column,
  Text,
  Row,
  Link,
  TextProps,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
interface ParagraphProps {
  type: string
  value: string
  url?: string
}
interface LegalProps {
  title: string
  paragraph: ParagraphProps[]
}

export const LegalTerms = ({
  title,
  paragraph
}: LegalProps): React.ReactElement => {
  const { isMobile } = useMobile()

  return (
    <>
      {title && (
        <>
          <Spacer.Horizontal size={isMobile ? 32 : 48} />
          <Column
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-background-gray-light')}
            justifyContent='center'
          >
            <Row
              isWrap
              maxWidth={getGlobalStyle('--width-max-desktop')}
              padding='24px 24px'
            >
              <Text
                fontSize='md'
                fontWeight='semibold'
              >
                {title}
              </Text>
              <Spacer.Horizontal size={8} />
              <Text isFlex={false}>
                {paragraph?.map((data, index) => {
                  const props: Omit<TextProps, 'children'> = {
                    type: 'span',
                    fontSize: 'md',
                    fontWeight: data?.type === 'bold' ? 'semibold' : 'regular',
                    underline: data?.type === 'underline' ? 'always' : 'none',
                    isFlex: false
                  }

                  if (data?.url) {
                    return (
                      <Link
                        href={data?.url}
                        key={index?.toString()}
                      >
                        <Text
                          {...props}
                          clickable='pointer'
                        >
                          {data.value}
                        </Text>
                      </Link>
                    )
                  }

                  return (
                    <Text
                      key={index?.toString()}
                      {...props}
                    >
                      {data.value}
                    </Text>
                  )
                })}
              </Text>
            </Row>
          </Column>
        </>
      )}
    </>
  )
}
