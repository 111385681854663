import { ReactChild } from 'react'
import { Container, Icon, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface CouponDetailPageProps {
  children?: ReactChild
  hideHeader?: boolean
  hideHeaderMargin?: boolean
  onToggle?: () => void
  open?: boolean
}

export const CouponDetailPage = ({
  children,
  hideHeader,
  hideHeaderMargin,
  onToggle,
  open
}: CouponDetailPageProps) => {
  const handleBackClick = () => {
    if (onToggle) {
      onToggle()
    }
  }

  if (!open) {
    return null
  }

  return (
    <Container
      isWrap
      margin={hideHeaderMargin ? '' : '42px 0'}
    >
      <Row
        alignItems='center'
        clickable='pointer'
        hidden={hideHeader}
        onClick={handleBackClick}
        padding='16px 22px'
      >
        <Icon
          color={getGlobalStyle('--color-text-black')}
          name='ArrowBackNavigate'
        />

        <Container margin='0 0 0 16px'>
          <Text fontWeight='semibold'>Volver a mis cupones</Text>
        </Container>
      </Row>

      <Row
        backgroundColor={getGlobalStyle('--color-background-gray-light')}
        gap={'12px'}
        padding='16px'
      >
        {children}
      </Row>
    </Container>
  )
}
