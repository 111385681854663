import { useUserAlvi } from '@smu-chile/pkg-unimarc-hooks'
import { useCouponsAlvi } from '@smu-chile/pkg-unimarc-hooks/hooks/useCouponsAlvi'
import { useCouponsCarousel } from 'components/CouponCarousel/helpers/useCouponCarousel'
import { useEffect, useState } from 'react'

const COUPONS_SIZE = 4
const USER_VALIDATION_STATUS = '2'

export type UseCouponsAvailableProps = {
  levels?: Array<string>
}

/**
 * Custom hook to determine if coupons are available for the current user.
 *
 * @param {Object} props - The properties to configure the hook.
 * @param {Array<string>} [props.levels] - An optional array of user levels that are eligible for coupons.
 *
 * @returns {Object} - An object containing:
 *  - `enabled` (boolean): Indicates whether coupons are available for the user.
 *
 * @example
 * const { enabled } = useCouponsAvailable({ levels: ["gold", "silver"] });
 * if (enabled) {
 *   console.log("Coupons are available for this user.");
 * }
 */
export const useCouponsAvailable = ({ levels }: UseCouponsAvailableProps) => {
  const [enabled, setEnabled] = useState(false)

  const coupons = useCouponsAlvi()
  const carouselCoupons = useCouponsCarousel({ coupons: coupons.coupons })
  const user = useUserAlvi()

  const couponsSize = coupons.isLoading ? COUPONS_SIZE : carouselCoupons.length
  const userLevel = user.data?.level
  const userValidationStatus = user.data?.validationStatus

  useEffect(() => {
    if (
      couponsSize >= COUPONS_SIZE &&
      levels.includes(userLevel) &&
      userValidationStatus !== USER_VALIDATION_STATUS
    ) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }, [coupons, user])

  return { enabled }
}
