import {
  Backbone,
  Column,
  Container,
  Horizontal,
  Vertical,
  Row,
  BackboneShelves
} from '@smu-chile/pkg-unimarc-components'
import { BackboneTitle } from './BackboneTitle'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'

export const BackboneHome = () => {
  return (
    <Container tagName='main'>
      <Column alignItems='center'>
        <BigScreen>
          <>
            <Horizontal size={32} />
            <Row maxWidth='1136px'>
              <Backbone
                borderRadius='8px'
                height={50}
              />
            </Row>
            <Horizontal size={24} />
            <Row maxWidth='1120px'>
              <Backbone
                borderRadius='8px'
                height={450}
              />
            </Row>
            <Horizontal size={16} />
            <Row maxWidth='32px'>
              <Backbone
                borderRadius='8px'
                height={8}
              />
              <Vertical size={64} />
              <Backbone
                borderRadius='8px'
                height={8}
              />
              <Vertical size={64} />
              <Backbone
                borderRadius='8px'
                height={8}
              />
            </Row>
            <Horizontal size={24} />
            <Row maxWidth='1136px'>
              <Backbone
                borderRadius='8px'
                height={112}
              />
            </Row>
            <BackboneTitle />
            <Row maxWidth='1150px'>
              <BackboneShelves shelvesQuantityDesktop={5} />
            </Row>
            <BackboneTitle />
            <Row maxWidth='1140px'>
              <Backbone
                borderRadius='12px'
                height={410}
              />
              <Vertical size={160} />
              <Backbone
                borderRadius='12px'
                height={410}
              />
              <Vertical size={160} />
              <Backbone
                borderRadius='12px'
                height={410}
              />
            </Row>
            <BackboneTitle />
            <Row maxWidth='1140px'>
              <Backbone
                borderRadius='12px'
                height={400}
              />
              <Vertical size={160} />
              <Backbone
                borderRadius='12px'
                height={400}
              />
              <Vertical size={160} />
              <Backbone
                borderRadius='12px'
                height={400}
              />
            </Row>
          </>
        </BigScreen>

        <SmallScreen>
          <Container tagName='main'>
            <Column alignItems='center'>
              <Horizontal size={16} />
              <Row
                justifyContent='center'
                maxWidth='92%'
              >
                <Backbone
                  borderRadius='10px'
                  height={56}
                />
              </Row>
              <Horizontal size={16} />
              <Row
                justifyContent='center'
                maxWidth='92%'
              >
                <Backbone
                  borderRadius='10px'
                  height={400}
                />
              </Row>
              <Horizontal size={12} />
              <Row maxWidth='30px'>
                <Backbone
                  borderRadius='8px'
                  height={8}
                />
                <Vertical size={64} />
                <Backbone
                  borderRadius='8px'
                  height={8}
                />
                <Vertical size={64} />
                <Backbone
                  borderRadius='8px'
                  height={8}
                />
              </Row>
              <Horizontal size={16} />
              <Row
                justifyContent='center'
                maxWidth='92%'
              >
                <Backbone
                  borderRadius='10px'
                  height={80}
                />
              </Row>
              <BackboneTitle />
              <Row>
                <BackboneShelves shelvesQuantityMobile={2} />
              </Row>
              <BackboneTitle />
              <Row maxWidth='92%'>
                <Column maxWidth='50px'>
                  <Backbone
                    borderRadius='12px'
                    height={188}
                  />
                </Column>
                <Vertical size={160} />
                <Column maxWidth='40px'>
                  <Backbone
                    borderRadius='12px'
                    height={188}
                  />
                </Column>
              </Row>
              <BackboneTitle />
              <Row maxWidth='92%'>
                <Column maxWidth='50px'>
                  <Backbone
                    borderRadius='12px'
                    height={192}
                  />
                </Column>
                <Vertical size={160} />
                <Column maxWidth='40px'>
                  <Backbone
                    borderRadius='12px'
                    height={192}
                  />
                </Column>
              </Row>
            </Column>
          </Container>
        </SmallScreen>
      </Column>
    </Container>
  )
}
