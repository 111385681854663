import { Column, Link, Picture } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
interface ImagesProps {
  desktop?: string
  mobile?: string
}
interface MonetiseBannerProps {
  padding: string
  images: ImagesProps[]
  urlLink: string
}

export const MonetiseBanner = ({
  padding,
  images,
  urlLink
}: MonetiseBannerProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const borderRadius = isMobile ? '12px' : '8px'
  const image = isMobile
    ? images?.find((img) => {
        return img.mobile
      })?.mobile
    : images?.find((img) => {
        return img.desktop
      })?.desktop

  return (
    <>
      {image && (
        <Column
          alignItems='center'
          maxWidth={getGlobalStyle('--width-max-desktop')}
          padding={padding}
        >
          <Link
            clickable={urlLink !== undefined}
            href={urlLink}
            underline='none'
          >
            <Picture
              alt='banner'
              borderRadius={borderRadius}
              height='100%'
              src={image}
              width='100%'
            />
          </Link>
        </Column>
      )}
    </>
  )
}
