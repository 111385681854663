import {
  cleanRut,
  formatRut,
  sanitizeRut,
  validateRut
} from '@smu-chile/pkg-unimarc-components/utils'
import { useEffect, useState } from 'react'
import { emailPattern } from 'shared/utils/constanst'

export interface IUseMembershipQueryLevelProps {
  value: { rut: string; email?: string }
  error: Record<string, string>
  rutStatus: TRutStatus
  emailStatus: TRutStatus
  isSelectCheck: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCleanRut: () => void
  handleCleanEmail: () => void
  handleSelectCheck: (value: boolean) => void
}

export type TRutStatus = '' | 'success' | 'error'

export const useQueryLevelMembershipForm =
  (): IUseMembershipQueryLevelProps => {
    const [value, setValue] = useState<IUseMembershipQueryLevelProps['value']>({
      rut: '',
      email: ''
    })
    const [rutError, setRutError] = useState<Record<string, string>>({})
    const [emailError, setEmailError] = useState<Record<string, string>>({})
    const [rutStatus, setRutStatus] = useState<TRutStatus>('')
    const [emailStatus, setEmailStatus] = useState<TRutStatus>('')
    const [isSelectCheck, setIsSelectCheck] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === 'rut') {
        if (cleanRut(e.target.value).length < 10) {
          setValue({ ...value, rut: formatRut(sanitizeRut(e.target.value)) })
        }
      }
      if (e.target.name === 'email') {
        setValue({
          ...value,
          email: e.target.value.replace(
            /[^a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-@]/g,
            ''
          )
        })
      }
    }

    const handleRutError = (value: string) => {
      setRutError({ rut: value })
      setRutStatus(value.length > 0 ? 'error' : '')
    }
    const handleEmailError = (value: string) => {
      setEmailError({ email: value })
      setEmailStatus(value.length > 0 ? 'error' : '')
    }

    const handleCleanRut = () => {
      setValue({ ...value, rut: '' })
      handleRutError('')
    }
    const handleCleanEmail = () => {
      setValue({ ...value, email: '' })
      handleEmailError('')
    }

    const handleSelectCheck = (value: boolean) => {
      setIsSelectCheck(value)
    }

    // in debounce validate rut with /auth/register
    // service
    useEffect(() => {
      const debounceValidateRut = setTimeout(() => {
        if (cleanRut(value.rut).length > 7 && validateRut(value.rut)) {
          setRutStatus('success')
          handleRutError('')
        } else if (cleanRut(value.rut).length === 0) {
          handleRutError('')
          setRutStatus('')
        } else {
          handleRutError('Ingresa un RUT válido')
        }
      }, 1000)
      return () => {
        clearTimeout(debounceValidateRut)
      }
    }, [value.rut])
    useEffect(() => {
      const debounceValidateEmail = setTimeout(() => {
        if (emailPattern.test(value.email)) {
          setEmailStatus('success')
          handleEmailError('')
        } else if (value.email.length === 0) {
          setEmailStatus('')
          handleEmailError('')
        } else {
          handleEmailError('Ingresa un correo electrónico válido')
        }
      }, 1000)
      return () => {
        clearTimeout(debounceValidateEmail)
      }
    }, [value.email])

    return {
      handleChange,
      handleCleanRut,
      handleCleanEmail,
      handleSelectCheck,
      isSelectCheck,
      rutStatus,
      emailStatus,
      value,
      error: {
        rut: rutError.rut,
        email: emailError.email
      }
    }
  }
