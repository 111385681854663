import { Backbone, Container } from '@smu-chile/pkg-unimarc-components'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'

export const FilterButtonsLoader = () => {
  return (
    <>
      <Container maxWidth='65px'>
        <Backbone
          borderRadius='32px'
          height={35}
        />
      </Container>
      <Container maxWidth='125px'>
        <Backbone
          borderRadius='32px'
          height={35}
        />
      </Container>

      <BigScreen>
        <>
          <Container maxWidth='100px'>
            <Backbone
              borderRadius='32px'
              height={35}
            />
          </Container>
          <Container maxWidth='90px'>
            <Backbone
              borderRadius='32px'
              height={35}
            />
          </Container>
          <Container maxWidth='100px'>
            <Backbone
              borderRadius='32px'
              height={35}
            />
          </Container>
        </>
      </BigScreen>
    </>
  )
}
