import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import {
  generateBreadcrumbJoin,
  useContentful,
  useIntelligenceSearch,
  usePagination
} from '@smu-chile/pkg-unimarc-hooks'
import { changeUrlCategories, promotionsOfShelves } from '../../shared/helpers'
import { dataForBreadcrumbs } from 'shared/helpers'
import { ErrorPageSearch } from './ErrorPageSearch'
import { paginationCal } from 'shared/helpers/paginationCal'
import { SearchBodyProduct, Main } from './SearchBodyProduct'

interface ProductSearchPagePros {
  page: number
  search: string
}

export const ProductSearchPage = ({ search, page }: ProductSearchPagePros) => {
  const router = useRouter()
  const [offSet, setOffSet] = useState(0)
  const [limit, setLimit] = useState(49)
  const { breadcrumbData, title } = dataForBreadcrumbs({ router, search })
  const dataBreadCrumb = changeUrlCategories(breadcrumbData)
  // Get all promotions available in contenful
  const resultPromotions = useContentful({
    options: { content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE }
  })
  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })

  const resultIntelligenceSearch = useIntelligenceSearch({
    search: search.trim(),
    from: offSet,
    to: limit
  })

  const { availableProducts, notAvailableProducts } =
    resultIntelligenceSearch?.data || {}
  const products = [
    ...(availableProducts || []),
    ...(notAvailableProducts || [])
  ]
  const totalQuantity = parseInt(resultIntelligenceSearch?.data?.resources) || 0
  const isLoadingPage =
    resultIntelligenceSearch.isLoading || resultPromotions.isLoading

  const setProductsRange = (page: number) => {
    const { offset, end } = paginationCal({ page, limit: 50 })
    setLimit(end)
    setOffSet(offset)
  }

  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: setProductsRange
  })

  propsNavigation['urlParams'] = `?q=${search}`

  const dataOfBodyPageProducts = {
    breadcrumbData: dataBreadCrumb,
    title,
    totalQuantity,
    propsNavigation,
    isLoadingPage,
    products,
    dataPromotions
  }
  useEffect(() => {
    setProductsRange(page)
  }, [])

  useEffect(() => {
    resultIntelligenceSearch.remove()
    resultIntelligenceSearch.refetch()
  }, [offSet, limit])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  if (!isLoadingPage && totalQuantity == 0) {
    return (
      <Main>
        <ErrorPageSearch search={search} />
      </Main>
    )
  }

  return <SearchBodyProduct {...dataOfBodyPageProducts} />
}
