import {
  breadcrumbJoin,
  dataLayerProductEvent,
  datalayerSimpleEvent,
  productProps
} from '@smu-chile/pkg-unimarc-hooks'
import { Item } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { productCategoriesJoin } from './analyticsHelpers'

declare global {
  interface Window {
    dataLayer: { push?: (props?: object) => void }
  }
}

const checkoutEnhanced = (data: { data?: { items?: Item[] } }) => {
  if (data.data.items) {
    const mark = {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Carrito' },
          products: []
        }
      }
    }

    for (const products of data.data.items) {
      mark.ecommerce.checkout.products.push({
        name: products?.name || null,
        id: products?.id || null,
        price: products?.priceDefinition?.calculatedSellingPrice || null,
        brand: products?.additionalInfo?.brandName || null,
        category: productCategoriesJoin(products) || null,
        quantity: products?.quantity || null,
        dimension10: null,
        // dimension11: Math.abs(priceTagsSum(products)) || null,
        dimension12: null
      })
    }
    window.dataLayer.push(mark)
  }
}

const productDataLayerPush = (event, items) => {
  window.dataLayer.push({
    event: event,
    ecommerce: {
      items: items
    }
  })
}

interface DataLayerItem {
  ahorro: number | string
  index: number
  item_brand: string
  item_id: string
  item_list_id: string
  item_list_name: string
  item_name: string
  oferta: number
  'precio-lista': number | string
  price: number | string
  quantity: number
  stock: number
}

interface DataLayerProductProps {
  items?: DataLayerItem[]
}

const pdpImpress = (dataLayerInfo: DataLayerProductProps) => {
  if (dataLayerInfo?.items.length == 0) return

  productDataLayerPush('view_item', dataLayerInfo.items)
}

interface InteractiveEventProps {
  event: string
  'event-category': string
  'event-label': string
}

const interactiveEvent = (dataLayerInfo: InteractiveEventProps) => {
  if (!dataLayerInfo) return

  window.dataLayer.push({
    event: dataLayerInfo.event,
    'event-category': dataLayerInfo['event-category'],
    'event-label': dataLayerInfo['event-label']
  })
}

const handlePdpTags = ({ product, userId, orderform }) => {
  const listId = 'PDP_' + breadcrumbJoin.replace(/\//g, '_')
  const listName = 'PDP ' + breadcrumbJoin
  const items = [productProps({ product, listId, listName })]
  const assignedStore =
    Object.values(orderform).length > 1
      ? `${orderform?.['salesChannel']} Alvi - ${orderform?.['address']?.['neighborhood']}`
      : '1'

  dataLayerProductEvent({
    event: 'view_item',
    items,
    assigned_store: assignedStore,
    orderform_id: orderform?.['orderFormId'],
    user_id: userId,
    saleschannel: orderform?.['salesChannel'] || '1'
  })
}

const clickPlp = (labelVal: string | number, event: string) => {
  const clickedText = labelVal == '/' ? 'Inicio' : labelVal
  const category = breadcrumbJoin?.search(/búsqueda/i) == -1 ? 'PLP' : 'SRP'
  datalayerSimpleEvent({
    event,
    eventCategory: event == 'breadcrumb' ? breadcrumbJoin : category,
    eventLabel: clickedText.toString()
  })
}

export {
  checkoutEnhanced,
  clickPlp,
  handlePdpTags,
  interactiveEvent,
  pdpImpress
}
