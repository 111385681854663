import { Button, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { BaseContainerProps } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { FilterButtonsLoader } from '../FilterButtonsLoader/FilterButtonsLoader'
import styles from './FilterButtons.module.css'

export interface FilterButtonItem {
  label: string
  active?: boolean
  onClick: () => void
}

export interface FilterButtonsProps
  extends Partial<Omit<BaseContainerProps, 'ref'>> {
  isLoading?: boolean
  items: FilterButtonItem[]
}

export const FilterButton = ({
  isLoading,
  items,
  ...props
}: FilterButtonsProps) => {
  if (isLoading) {
    return <FilterButtonsLoader />
  }
  return (
    <Row
      customClassName={styles.filterButtons}
      gap={'4px'}
      maxWidth='100%'
      scrollbarHidden
      {...props}
    >
      {Array.isArray(items) &&
        items.map((item, index) => {
          return (
            <Button
              background={
                item.active
                  ? getGlobalStyle('--color-alvi-primary-background-blue')
                  : getGlobalStyle('--color-base-white')
              }
              border={
                item.active
                  ? `1px solid ${getGlobalStyle('--color-alvi-primary-blue3')}`
                  : `1px solid ${getGlobalStyle('--color-neutral-gray-dark')}`
              }
              borderRadius='2rem'
              color={
                item.active
                  ? getGlobalStyle('--color-alvi-primary-blue3')
                  : getGlobalStyle('--color-neutral-gray-dark')
              }
              fontWeight={'normal'}
              key={index}
              label={item.label}
              minWidth='initial'
              onClick={item.onClick}
              padding={'8px 12px'}
              width='max-content'
            />
          )
        })}
    </Row>
  )
}
