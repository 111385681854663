import { NoStockProducts } from '@smu-chile/pkg-unimarc-components'
import { DataResponseCart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICart'

export interface NoStockModalProps {
  data?: DataResponseCart
  isOpen?: boolean
  warnings?: Array<string>
  onClick?: () => void
}

export const NoStockModal = ({ data, isOpen, onClick }: NoStockModalProps) => {
  return (
    <>
      <NoStockProducts
        data={data}
        isOpen={isOpen}
        onClick={onClick}
      />
    </>
  )
}
