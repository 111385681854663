// https://it-smu.atlassian.net/wiki/spaces/Ecommerce1/pages/787644419/Subscripci+n
export const MEMBERSHIP_LEVELS = {
  oro: {
    url: 'images/socioOro.png',
    alt: 'imagen de socio oro',
    title: 'socio oro'
  },
  plata: {
    url: 'images/socioPlata.png',
    alt: 'imagen de socio platino',
    title: 'socio platino',
    tipo: 'socio platino'
  },
  vip: {
    url: 'images/socioVip.png',
    alt: 'imagen de socio diamante',
    title: 'socio diamante',
    tipo: 'socio diamante'
  },
  clubalvi: {
    url: 'images/socioClub.png',
    alt: 'imagen de socio club',
    title: 'socio club',
    tipo: 'socio club'
  }
}

export const TRANSBANK_FOOTER_IMG = {
  0: '3KsF8CJVJWBVpqOykXGv3i'
}

export const CONTENTFUL_EMOJI_CELEBRATION = '1veWIrueF6IumnsVPs2UKv'
export const CONTENTFUL_EMOJI_LOCATION = '1yhiigahLxpFPzVr1QwGNz'
export const CONTENTFUL_EMOJI_STORE_DELIVERY = '7sSegZ2gp3NVGkOKJYNcmt'
export const CONTENTFUL_EMOJI_STORE_PICKUP = '68czQHpsWvrjtdgVKE8E9W'

export const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
