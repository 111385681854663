/* eslint-disable react/jsx-sort-props */
import Head from 'next/head'
import parse from 'html-react-parser'
import {
  breadSchema,
  organizationSchema,
  productSchema,
  webSiteSchema
} from './schema'
import { normalizeString } from '../../shared/helpers/middleware'

export interface ProductProps {
  availability?: string
  brand?: string
  description?: string
  images?: Array<string>
  name?: string
  price: string | number
  sku?: string
}

interface HeaderProps {
  canonicalPath?: string
  categories?: Array<string>
  contentDescription?: string
  metaRobot?: string
  pageImg?: string
  pageNumber?: string
  productInfo?: ProductProps
  titleName?: string
  type?:
    | 'HOME'
    | 'PLP'
    | 'PDP'
    | 'SRP'
    | 'COLLECTION'
    | 'STATIC'
    | 'LANDING'
    | 'BRAND'
}

export const MetaHead = ({
  canonicalPath = '',
  categories = [],
  contentDescription = '',
  metaRobot = 'index,follow',
  pageImg,
  pageNumber,
  productInfo,
  titleName = '',
  type
}: HeaderProps) => {
  const customTitle = titleName ? parse(`${titleName} | Alvi`) : 'Alvi'
  const contentTitle = titleName ? `${titleName} | Alvi` : 'Alvi'
  const productListTitle = pageNumber
    ? parse(`${titleName} - Página ${pageNumber} | Alvi`)
    : null
  const schemaBread = ['PDP', 'PLP', 'COLLECTION']
  const pageImageFilter = {
    PLP: pageImg,
    PDP: productInfo?.images[0]
  }
  const canonicalUrl = `https://www.alvi.cl${normalizeString(canonicalPath)}`
  const metaRobotTag =
    canonicalUrl.includes('login') ||
    canonicalUrl.includes('gclid') ||
    type === 'SRP'
      ? 'noindex,follow'
      : metaRobot

  enum urlType {
    'PLP' = 'https://www.alvi.cl/category/',
    'BRAND' = 'https://www.alvi.cl/brand/',
    'PDP' = 'https://www.alvi.cl/category/',
    'SRP' = 'https://www.alvi.cl/',
    'COLLECTION' = 'https://www.alvi.cl/ofertas/',
    'STATIC' = 'https://www.alvi.cl/static/',
    'LANDING' = 'https://www.alvi.cl/page/'
  }
  const breadCrumbs = breadSchema(categories, urlType?.[type], pageNumber)

  return (
    <Head>
      <title>{productListTitle ?? customTitle}</title>

      {/* 1. Landings & static pages 'page title'
          2. PLP page title or empty
          3. PDP produtc description
      */}
      <meta
        name='description'
        content={productInfo?.description ?? contentDescription}
      />

      {/* Sitewide default value for all pages */}
      <meta
        property='og:type'
        content='website'
      />
      <meta
        property='og:site_name'
        content='Alvi.cl'
      />

      {/* default value for all pages */}
      <meta
        name='robots'
        content={metaRobotTag}
      />

      {/* Same title as <Title /> */}
      <meta
        property='og:title'
        content={contentTitle}
      />

      {/* Same value as Canonical or actual url  */}
      <meta
        property='og:url'
        content={canonicalUrl}
      />

      {/* Same value as Meta description*/}
      <meta
        property='og:description'
        content={productInfo?.description ?? contentDescription}
      />

      {/*
        1. PLP  category url/photo
        2. PDP  product url/photo
        3. default img given by marketing 1200 px X 627px
        */}
      <meta
        property='og:image'
        content={pageImageFilter[type] || 'defaultUrl'}
      />

      {/* Twitter default value */}
      <meta
        name='twitter:card'
        content='summary'
      />
      <meta
        name='twitter:site'
        content='@AlviChile'
      />

      {/* Viewport default value */}
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
      />
      <link
        href='/favicon.ico'
        rel='icon'
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/images/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/images/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/images/favicon-16x16.png'
      />
      <link
        rel='manifest'
        href='/site.webmanifest'
      />
      {/* url 'https://www.alvi.cl/{pageName}'
        1. PDP url + pagination
        2. SRP excluded from canonical
        3. PLP url without query params
       */}
      {type !== 'SRP' && (
        <link
          href={canonicalUrl}
          rel='canonical'
        />
      )}
      {type === 'HOME' && (
        <>
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: webSiteSchema }}
          />
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: organizationSchema }}
          />
        </>
      )}
      {schemaBread.includes(type) && breadCrumbs?.length >= 1 && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: breadCrumbs }}
        />
      )}
      {type === 'PDP' && productInfo && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: productSchema(productInfo, canonicalUrl)
          }}
        />
      )}
    </Head>
  )
}
