/**
 * Gets descriptive text based on the retry after seconds.
 * @param {number} retryAfter - locked seconds.
 * @returns {string}
 */
export const getRetryAfterText = (retryAfter: number): string => {
  let suffix = 'segundos'
  let time = retryAfter

  if (retryAfter > 60) {
    suffix = 'minutos'
    time = retryAfter / 60
  }

  return `${time.toFixed(0)} ${suffix}`
}
