import { Acordeon, Container } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './CouponDetailLegal.module.css'

export interface CouponDetailLegalProps {
  couponEndDate?: string
  couponLegal?: string
  couponStartDate?: string
}

export const CouponDetailLegal = ({ couponLegal }: CouponDetailLegalProps) => {
  return (
    <Acordeon
      alignItemsIcon='end'
      fontWeightText='regular'
      maxWidthText='100%'
      positionText='bottom'
      titleClose='Bases Legales'
      titleOpen='Bases Legales'
      titleProps={{
        buttonProps: {
          type: 'plain'
        },
        containerProps: {
          backgroundColor: getGlobalStyle('--color-neutral-white'),
          border: `1px solid ${getGlobalStyle('--color-neutral-gray')}`,
          borderRadius: '32px',
          padding: '0 24px'
        }
      }}
    >
      <Container
        className={styles.container}
        color={getGlobalStyle('--color-text-black')}
        margin='8px 0 0 24px'
        padding='8px'
      >
        {couponLegal}
      </Container>
    </Acordeon>
  )
}
