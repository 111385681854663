import {
  Container,
  Footer as FooterComponent,
  PromiseBrandData
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  FooterLoader,
  BottomFooterData,
  MiddleFooterData
} from '@smu-chile/pkg-unimarc-components/stories/organisms/Footer'
import {
  getContentFulDataContent,
  getEntriesByContentType,
  useContentful
} from '@smu-chile/pkg-unimarc-hooks'
import { TRANSBANK_FOOTER_IMG } from 'shared/utils/constanst'

export const Footer = (): React.ReactElement => {
  const { data, isLoading } = useContentful({
    id_contentful: 'footer',
    options: {
      'sys.contentType.sys.id[in]':
        'webTopFooter,webMiddleFooter,webBottomFooter'
    }
  })

  const transbankIcon = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-transbank-img',
    options: {
      'sys.id': TRANSBANK_FOOTER_IMG[0]
    },
    type: 'assets'
  })

  const transbankImg =
    transbankIcon.data?.['items']?.[0]?.fields?.file?.url || ''

  if (isLoading) {
    return <FooterLoader />
  }
  const contentFullData = getContentFulDataContent(data)

  if (Array.isArray(contentFullData) && contentFullData.length === 0) {
    return null
  }

  const handleDataTopFooter = (): PromiseBrandData[] => {
    return getEntriesByContentType({
      data: contentFullData,
      contentType: 'topFooterItem',
      platForm: 'Web Alvi eComm',
      site: 'alvi'
    })[0].entries as PromiseBrandData[]
  }

  const handleDataMiddleFooter = (): MiddleFooterData[] => {
    return getEntriesByContentType({
      data: contentFullData,
      contentType: 'middleFooterItem',
      platForm: 'Web Alvi eComm',
      site: 'alvi'
    }) as MiddleFooterData[]
  }

  const handleDataBottomFooter = (): BottomFooterData[] => {
    return getEntriesByContentType({
      data: contentFullData,
      contentType: 'bottomFooterItem',
      platForm: 'Web Alvi eComm',
      site: 'alvi'
    }) as BottomFooterData[]
  }

  return (
    <Container
      id='footer--my-account'
      zIndex={getGlobalStyle('--z-index-1')}
    >
      <FooterComponent
        dataBottomFooter={handleDataBottomFooter()}
        dataLinks={handleDataMiddleFooter()}
        dataTopFooter={handleDataTopFooter()}
        site='alvi'
        transbankImg={transbankImg}
      />
    </Container>
  )
}
