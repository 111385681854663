import {
  ResetPasswordModal,
  ResetPasswordModalProps
} from '@smu-chile/pkg-unimarc-components'

export type ResetPasswordModalBrowseProps = ResetPasswordModalProps

export const ResetPasswordModalBrowse = (
  props: ResetPasswordModalBrowseProps
) => {
  return (
    <ResetPasswordModal
      options={[
        {
          id: 'min',
          text: 'Mínimo 8 carácteres',
          validation: (value: string) => {
            return /.{8,}/.test(value)
          }
        },
        {
          id: 'lower',
          text: '1 minúscula (a-z)',
          validation: (value: string) => {
            return /[a-z]/.test(value)
          }
        },
        {
          id: 'number',
          text: '1 número (0-9)',
          validation: (value: string) => {
            return /\d/.test(value)
          }
        },
        {
          id: 'special',
          text: '1 carácter especial (Ej: *·#%)',
          validation: (value: string) => {
            return /\W|_/.test(value)
          }
        },
        {
          id: 'upper',
          text: '1 mayúscula (A-Z)',
          validation: (value: string) => {
            return /[A-Z]/.test(value)
          }
        }
      ]}
      subtitle='Escoge una contraseña que sea segura y fácil de recordar'
      title='Ingresa tu nueva contraseña'
      {...props}
    />
  )
}
