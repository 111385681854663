import {
  Column,
  Container,
  Icon,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { WrapTooltip, WrapTooltipProps } from './WrapTooltip'

export type CodeResendTooltipProps = WrapTooltipProps

export const CodeResendTooltip = (props: CodeResendTooltipProps) => {
  return (
    <WrapTooltip
      variant='info'
      {...props}
    >
      <Container>
        <Row
          alignItems='center'
          gap={8}
        >
          <Column maxWidth='max-content'>
            <Icon
              color={getGlobalStyle('--color-feedback-info')}
              customSize={20}
              name='InfoFill'
            />
          </Column>

          <Column margin='0 10px 0 0'>
            <Text
              color='black'
              display='block'
              fontSize='md'
              fontWeight='medium'
            >
              Hemos reenviado el código exitosamente.
            </Text>
          </Column>
        </Row>
      </Container>
    </WrapTooltip>
  )
}
